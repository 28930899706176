import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { showToastMessage } from "../../../helpers/toastr";
import DataTable from "react-data-table-component";
import { handleAppointmentListing, handleDeleteAppointment } from "../../../services/appointmentService";
import { formatTime } from "../../../helpers/formatTime";
import { formatDate } from "../../../helpers/formatDate";
import Loading from "../../../components/loader/Loading";
import ConfirmationPopupButton from "../../../components/ConfirmationPopup/ConfirmationPopupButton";
import ConfirmationPopup from "../../../components/ConfirmationPopup/ConfirmationPopup";
import { debounce, orderBy } from 'lodash';
import SearchFilter from "../../../components/pagination/SearchFilter.jsx";
import { useSelector } from "react-redux";
import checkPermission from "../../../helpers/checkPermission";
import showDeletePopup from "../../../helpers/showDeletePopup.js";
import { conditionalRowStyles } from "../../../helpers/conditionalRowStyles.js";
import { handleGetOtp } from "../../../services/billingService.js";
import showOtpVerficationPopup from "../../../helpers/showOtpVerficationPopup.js";
import { handleOtpVerfication } from "../../../services/billingService.js";
import showEditPopup from "../../../helpers/showEditPopup.js";

const AppointmentListing = ({ selectGlobalOrg, filterData }) => {
    const userData = useSelector((state) => state.userData);
    const [popupID, setPopupID] = useState('');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [perPage, setPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const hasAdminRole = userData?.role?.role === 'admin' ? true : false;
    const hasCoOwnerRole = userData?.role?.role === 'co-owner' ? true : false;
    const handlePageChange = page => {
        fetchData(filterData, page, perPage);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const columns = [
        {
            name: 'Name',
            selector: row => row.get_customer_info?.name,
            sortable: true,
            sortFunction: (a, b) => a.get_customer_info?.name.localeCompare(b.get_customer_info?.name),
        },
        {
            name: 'Mobile Number',
            selector: row => row.get_customer_info?.phone_number,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.date ? formatDate(row.date) : '',
            sortable: true,
        },
        {
            name: 'Time',
            selector: row => row.time ? formatTime(row.time) : '',
            sortable: true,
        },
    ];

    if (hasAdminRole) {
        columns.push({
            name: 'Reason',
            selector: row => row?.has_latest_delete_request?.length > 0 ? row?.has_latest_delete_request[0].reason : '',
            sortable: true,
        });
    }
    columns.push({
        name: 'Actions',
        cell: row => (
            <div className="d-flex gap-2">
                {checkPermission(userData, 'Update appointments') && (
                    <button
                        className="icon-sm-medium round-100 icontext-blue borderwidth-0 bg-white"
                        onClick={() => {
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record is deleted, you cannot edit it.');
                            } else {
                                handleEdit(row.uuid, 'edit');
                            }
                        }}
                    >
                        <i className="mdi mdi-lead-pencil menu-icon bodytext-24"></i>
                    </button>
                )}

                {checkPermission(userData, 'Delete appointments') && (
                    <ConfirmationPopupButton
                        handleDelete={() => {
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record already is deleted, you cannot delete it.');
                            } else {
                                handleDelete(row.uuid)
                            }
                        }}
                    />
                )}
            </div>
        ),
    });



    const navigate = useNavigate();
    const handleEdit = async (uuid) => {
        const reason = await showEditPopup();
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    navigate(`/edit-appointment/${uuid}`, { state: { reason } });
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            } else {

            }
        } else {
            if (reason !== '' && reason !== null) {
                try {
                    const requestType = 'edit';
                    const result = await handleGetOtp(uuid, reason, requestType);
                    while (true) {
                        let otp = await showOtpVerficationPopup('info');
                        if (otp !== '' && otp !== null) {
                            try {
                                const otpResult = await handleOtpVerfication(uuid, otp, reason);
                                showToastMessage('success', 'OTP verified successfully');
                                try {
                                    navigate(`/edit-appointment/${uuid}`, { state: { reason } });
                                } catch (error) {
                                    console.log(error);
                                    showToastMessage('error', error.response.data.message);
                                }
                                break; // Exit the loop if everything is successful
                            } catch (error) {
                                if (error && error.response && error.response.data && error.response.data.error) {
                                    showToastMessage('error', error.response.data.error);
                                } else {
                                    showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                                }
                                console.error('Error:', error);
                                otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                            }
                        } else {
                            showToastMessage('info', 'Edit operation was cancelled.');
                            break; // Exit the loop if the operation is cancelled
                        }
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', 'An unexpected error occurred while requesting OTP.');
                }
            } else {
                showToastMessage('info', 'Edit operation was cancelled.');
            }
        }
    };



    const handleDelete = async (uuid) => {
        const reason = await showDeletePopup();

        if (reason === '' || reason == null) {
            showToastMessage("error", "Reason for deletion is required!");
            return;
        }
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleDeleteAppointment(uuid, reason);
                    if (result.data) {
                        showToastMessage('success', result.data.message);
                        fetchData(undefined, currentPage, perPage);
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            }
        } else {
            const requestType = 'delete';
            const result = await handleGetOtp(uuid, reason, requestType);
            while (true) {
                let otp = await showOtpVerficationPopup('info');
                if (otp !== '' && otp !== null) {
                    try {
                        const otpResult = await handleOtpVerfication(uuid, otp, reason);
                        showToastMessage('success', 'OTP verified successfully');
                        try {
                            await handleDeleteAppointment(uuid, reason);
                            fetchData(filterData, currentPage, perPage);
                        } catch (error) {
                            console.log(error);
                            showToastMessage('error', error.response.data.message);
                        }
                        break; // Exit the loop if everything is successful
                    } catch (error) {
                        if (error && error.response && error.response.data && error.response.data.error) {
                            showToastMessage('error', error.response.data.error);
                        } else {
                            showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                        }
                        console.error('Error:', error);
                        otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                    }
                } else {
                    showToastMessage('info', 'Delete operation was cancelled.');
                    break; // Exit the loop if the operation is cancelled
                }
            }
        }



    };


    useEffect(() => {
        if (filterData !== undefined) {
            const parsedFilterData = JSON.parse(filterData);
            if (parsedFilterData.length === 0) {
                return;
            }
        }

        const debouncedFetchData = debounce(fetchData, 300);
        const fetchDataOnChange = () => {
            debouncedFetchData(filterData, 1, perPage, searchValue);
        };

        if (searchValue) {
            fetchDataOnChange();
        } else {
            fetchData(filterData, 1, perPage, searchValue);
        }

        return () => {
            debouncedFetchData.cancel();
        };
    }, [selectGlobalOrg, perPage, searchValue, filterData]);

    const fetchData = async (filterData, page, per_page, searchText) => {
        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, option => option.value);

        try {
            const result = await handleAppointmentListing(initialOrgIds, filterData, page, per_page, searchText);
            if (result.data) {
                setData(result.data.data);
            }
        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="row">
                {/* <ConfirmationPopup handleDelete={handleDelete} popupID={popupID} setPopupID={setPopupID} /> */}
                <div className="col-lg-12 grid-margin">
                    <div className="card">
                        <div className="card-header bg-transparent">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="card-title heading-h3 mb-0">Appointment Listing</h4>
                                {checkPermission(userData, 'Create appointments') && (
                                    <NavLink to="/appointments" className="btn btn-success d-flex gap-2 bodytext-16 align-items-center modalButtonCommon">
                                        <i className="fa fa-plus-circle"></i> New Appointment
                                    </NavLink>
                                )}
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <DataTable
                                        columns={columns}
                                        data={data?.data}
                                        pagination
                                        paginationPerPage={perPage}
                                        paginationServer
                                        paginationTotalRows={data?.total}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        subHeader
                                        subHeaderComponent={<SearchFilter setSearchValue={setSearchValue} searchValue={searchValue} />}
                                        paginationComponentOptions={{
                                            rangeSeparatorText: 'of',
                                            noRowsPerPage: true,
                                        }}
                                        {...(hasAdminRole && { conditionalRowStyles })}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AppointmentListing;
