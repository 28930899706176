import React, { useEffect, useState } from "react";
import { Listing } from "../../../components/appointments/Listing";
import { showToastMessage } from "../../../helpers/toastr";
import { CustomerDetail } from "../../../components/appointments/CustomerDetail";
import { handleStaffListing } from "../../../services/staffServices";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import { SelectStaffService } from "../../../components/appointments/SelectStaffService";
import { handleServiceListing } from "../../../services/service";
import { handleAppointment, handleEditAppointment } from "../../../services/appointmentService";
import { handleEditCustomer } from "../../../services/customerServices";
import { getCurrentDate } from "../../../helpers/getCurrentDate";
import { getCurrentTime } from "../../../helpers/getCurrentTime";


const EditAppointment = ({ selectGlobalOrg }) => {

    const navigate = useNavigate();
    // const [discountLimit, setDiscountLimit] = useState('40');
    // const [showDiscount, setshowDiscount] = useState('none');

    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [staffData, setStaffData] = useState([]);
    const [services, setServices] = useState([]);
    const [btn, setBtn] = useState(false);
    const [errors, setErrors] = useState({});
    const [appointmentData, setAppointmentData] = useState([]);

    const uuid = useParams('uuid');
    const location = useLocation();
    const reason = location.state?.reason;



    const [formData, setFormData] = useState({

        // customer
        name: '',
        phone: '',
        email: '',
        gender: 'male',
        orgId: '',
        wallet: 0,
        refId: '',
        type: '',
        date: getCurrentDate(),
        time: getCurrentTime(),

        // Service Billing
        billingRemark: '',
        // subTotal:'',
        // billingTax:'',
        // discountSymbol:'%',
        // discountedAmount:'',
        // discount:'',
        // paymentMode:'',
        // grandTotal:'',
        // specialDiscount:'',
        // walletStatus:false,
        // walletAmount:'0.00',

        // Multiple billing service
        services: [
            {
                serviceId: '',
                staffId: '',
                // coStaffId:'',
                coStaffId: [],
                tax: '18',
                quantity: '1',
                price: '',
                total: '',
            }
        ]
    });

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = "Name is required";
            isValid = false;
        }

        // if (!formData.paymentMode.trim()) {
        //     newErrors.paymentMode = "Payment mode is required";
        //     isValid = false;
        // }

        // if (formData.discount > discountLimit) {
        //     if (formData.specialDiscount === "" || isNaN(formData.specialDiscount) || formData.specialDiscount <= discountLimit || formData.specialDiscount > 100) {
        //         newErrors.specialDiscount = `Special discount must be a number greater than ${discountLimit} and less than or equal to 100`;
        //         isValid = false;
        //     }
        // }




        // Validate phone
        // const phoneValue = formData.phone.trim(); 

        // if (!phoneValue) {
        //   newErrors.phone = "Phone is required";
        //   isValid = false;
        // }else if(phoneValue.length !== 10){
        //     newErrors.phone = "Phone number should be 10 digits";
        //     isValid = false;
        // }else if(!phoneValue.match(/[1-4]/g)){
        //     newErrors.phone = "Phone number should be integers";
        //     isValid = false;
        // }

        // if (phoneValue.length !== 10) { // Check for exactly 10 digits
        //     newErrors.phone = "Phone number should be 10 digits";
        //     isValid = false;
        //   }

        //   if (!formData.services.serviceId || formData.serviceId.length === 0) {
        //     newErrors.serviceId = "At least one service ID is required";
        //     isValid = false;
        // } else {
        // formData.services.forEach((service, index) => {
        //     if (!service.serviceId.trim()) {
        //         newErrors[`serviceId_${index}`] = `Service ID ${index + 1} is required`;
        //         isValid = false;
        //     }

        //         // Additional validations for other fields in each service object can be added here
        //     });

        setErrors(newErrors);



        return isValid;
    };

    const handleInputChange = (e) => {

        const { name, value, type, checked } = e.target;
        const processedValue = type === 'number' ? parseFloat(value) : value;


        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: processedValue,
        }));


        if (name == 'walletStatus') {

            console.log(checked, 'checked');

            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: checked
            }));
        }

        //   console.log(formData,'formDataformData');

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };


    useEffect(() => {

        fetchStaff();
        fetchServices();
        fetchAppointment(uuid);

    }, [selectGlobalOrg, uuid]);


    useEffect(() => {

        if (appointmentData && appointmentData.length != 0) {

            console.log(selectGlobalOrg, 'selectGlobalOrg');

            if (selectGlobalOrg && (appointmentData.org_id != selectGlobalOrg)) {

                showToastMessage('error', 'Organizaton changed and that record not exits in this record')
                navigate('/appointment-listing')
            }

            let servicesData;

            if (appointmentData.get_multiple_services && appointmentData.get_multiple_services.length > 0) {
                servicesData = appointmentData.get_multiple_services.map(service => ({
                    serviceId: service.service_id,
                    staffId: service.main_staff_id,
                }));
            } else {

                servicesData = [{
                    serviceId: '',
                    staffId: '',

                }];
            }

            handleCustomerClick(appointmentData?.get_customer_info?.uuid)

            setFormData({
                name: appointmentData.get_customer_info?.name,
                phone: appointmentData.get_customer_info?.phone_number,
                gender: appointmentData.get_customer_info?.gender,
                orgId: appointmentData.org_id,
                customerId: appointmentData.get_customer_info?.id,
                type: 'edit',
                date: appointmentData?.date,
                time: appointmentData?.time,
                refId: appointmentData.get_customer_info?.reference_id,

                billingRemark: appointmentData?.remark,
                uuid: appointmentData?.uuid,


                // Multiple billing service
                services: servicesData

            });
        }

    }, [appointmentData, selectGlobalOrg]);

    const handleCustomerClick = async (id) => {

        try {
            const result = await handleEditCustomer(id);
            if (result.data) {
                setSelectedCustomer(result.data?.data)
                // setShowDetail('block')
                // setShowDiv('none')
                // setShowGender('none')
            } else {
                // setShowGender('')
                // setShowDiv('none')
                // setShowDetail('none')
                // setSelectedCustomer([]);
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }

    const fetchAppointment = async (uuid) => {
        try {
            const result = await handleEditAppointment(uuid);

            if (result.data) {
                setAppointmentData(result.data?.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }
    }

    //   useEffect for fetch staff members

    const fetchStaff = async () => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

        try {
            const result = await handleStaffListing(initialOrgIds, 'employee', '0');

            if (result.data) {
                setStaffData(result.data?.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }

    const fetchServices = async () => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
        const status = 0;

        try {
            const result = await handleServiceListing(initialOrgIds, status);
            if (result?.data) {
                setServices(result?.data.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }


    const addRow = () => {
        setFormData({
            ...formData,
            services: [...formData.services, { serviceId: '', staffId: '' }]
        });
    };

    const removeRow = (index) => {
        setFormData(prevState => {
            if (prevState.services.length <= 1) {
                // Show toast message indicating that at least one service is required
                showToastMessage("error", "At least one service is required");
                return prevState; // Don't modify state
            }

            const updatedServices = [...prevState.services];
            updatedServices.splice(index, 1);

            // let subtotal = 0;
            // let totalTax = 0;
            // let discountAmt = 0;

            // updatedServices.forEach(service => {
            //     subtotal += service.price * service.quantity;
            //     totalTax += (service.price * service.quantity * 18) / 100; // Assuming tax rate is 18%
            // });

            // let grandTotal = subtotal + totalTax;

            // const walletAmount = formData.wallet;
            // const walletStatus = formData.walletStatus;
            // let amountToDeduct;

            // // console.log(walletStatus == true && grandTotal != 0,'condtion');

            // if(walletStatus == true && grandTotal != 0){
            //     amountToDeduct = Math.min(walletAmount, grandTotal);
            //     grandTotal -= amountToDeduct;
            // }

            // // Apply discount if available
            // let discount = prevState && prevState.discount ? prevState.discount : 0;
            // const discountSymbol = prevState && prevState.discountSymbol ? prevState.discountSymbol : '%';

            // if (discountSymbol == '%') {
            //     discountAmt = Math.min(subtotal * (discount / 100), subtotal);
            // } else {
            //     discountAmt = Math.min(discount, subtotal);
            // }

            // grandTotal -= discountAmt;

            return {
                ...prevState,
                //     walletAmount:amountToDeduct,
                services: updatedServices,
                //     subTotal: formatAmount(subtotal),
                //     billingTax: formatAmount(totalTax),
                //     grandTotal: formatAmount(grandTotal),
                //     discount: discount || '0',
                //     discountedAmount: formatAmount(discountAmt)
            };
        });
    };


    // const calculateTotalForRow = (quantity, price, tax) => {
    //     const totalPrice = parseFloat(quantity) * parseFloat(price);
    //     const totalTax = totalPrice * (parseFloat(tax) / 100);
    //     return formatAmount(totalPrice + totalTax);
    // };

    const handleFieldChange = (fieldName, value, index) => {
        console.log(fieldName, value, index, 'index');

        console.log(formData, 'formData');

        // const walletAmount = formData.wallet;
        // const walletStatus = formData.walletStatus;
        // let amountToDeduct;

        // if (fieldName === 'discount') {

        //     if (parseFloat(value) > parseFloat(discountLimit)) {
        //         setshowDiscount('');
        //         if(walletAmount > 0){
        //             setShowWallet('none');
        //         }

        //     } else {
        //         setshowDiscount('none');
        //         if(walletAmount > 0){
        //             setShowWallet('');
        //         }

        //     }
        // }

        setFormData(prevState => {
            const updatedServices = [...prevState.services];

            if (index !== undefined) {
                updatedServices[index] = {
                    ...updatedServices[index],
                    [fieldName]: value,
                    // total: calculateTotalForRow(
                    //     fieldName === 'quantity' ? value : updatedServices[index]?.quantity,
                    //     fieldName === 'price' ? value : updatedServices[index]?.price,
                    //     '18'
                    // ),
                    // tax:'18',
                };
            }

            // let subtotal = 0;
            // let totalTax = 0;
            // let discountAmt = 0;

            // updatedServices.forEach(service => {
            //     subtotal += service.price * service.quantity;
            //     totalTax += (service.price * service.quantity * 18) / 100; // Assuming tax rate is 18%
            // });


            // let grandTotal = subtotal + totalTax;



            // let discount = prevState && prevState.discount ? prevState.discount : "";

            // const discountSymbol = prevState && prevState.discountSymbol ? prevState.discountSymbol : '%';



            // let discountVar;

            // if(discount > discountLimit) {
            //     discountVar = discountLimit
            // }else{
            //     discountVar = discount;
            // }

            // if (discountSymbol == '%') {
            //     discountAmt = Math.min(subtotal * (discountVar / 100), subtotal);
            // } else {
            //     discountAmt = Math.min(discountVar, subtotal);
            // }

            // grandTotal -= discountAmt;

            // if(discount <= discountLimit) {

            //     if(fieldName === 'walletStatus'){

            //         if(walletStatus == false && grandTotal != 0){
            //             amountToDeduct = Math.min(walletAmount, grandTotal);
            //             grandTotal -= amountToDeduct;
            //         }

            //     }else
            //     {
            //         if(walletStatus == true && grandTotal != 0){
            //             amountToDeduct = Math.min(walletAmount, grandTotal);
            //             grandTotal -= amountToDeduct;
            //         }
            //     }

            // }            



            return {
                ...prevState,
                services: updatedServices,
                // walletAmount:amountToDeduct,
                // subTotal: formatAmount(subtotal),
                // billingTax: formatAmount(totalTax),
                // grandTotal: formatAmount(grandTotal),
                // discount: discount,
                // discountedAmount: formatAmount(discountAmt)
            };
        });


    };


    // const handleServicecategory = async (option, index) => {
    //     const id = option.value;
    //     if (!id) {
    //         return false;
    //     }
    //     try {
    //         const result = await handleEditService(id);
    //         if (result.data) {
    //             const newData = result.data?.data;

    //             setFormData(prevState => {
    //                 const updatedServices = [...prevState.services];
    //                 updatedServices[index] = {
    //                     ...updatedServices[index],
    //                     serviceId: newData.id,
    //                     price: newData.price,
    //                     quantity: '1',
    //                     tax:'18',
    //                     total: calculateTotalForRow('1', newData.price, '18')
    //                 };

    //                 let subtotal = 0;
    //                 let totalTax = 0;
    //                 let discountAmt = 0;

    //                 updatedServices.forEach(service => {
    //                     subtotal += service.price * service.quantity;
    //                     totalTax += (service.price * service.quantity * 18) / 100; // Assuming tax rate is 18%
    //                 });

    //                 let grandTotal = subtotal + totalTax;

    //                 const walletAmount = formData.wallet;
    //                 const walletStatus = formData.walletStatus;
    //                 let amountToDeduct;

    //                 // console.log(walletStatus == true && grandTotal != 0,'condtion');

    //                 if(walletStatus == true && grandTotal != 0){
    //                      amountToDeduct = Math.min(walletAmount, grandTotal);
    //                     // console.log(amountToDeduct,'amountToDeduct');
    //                     grandTotal -= amountToDeduct;
    //                 }

    //                 // console.log(amountToDeduct,'amountToDeduct');

    //                 // Apply discount if available
    //                 let discount = prevState && prevState.discount ? prevState.discount : 0;
    //                 const discountSymbol = prevState && prevState.discountSymbol ? prevState.discountSymbol : '%';

    //                 if (discountSymbol == '%') {
    //                     discountAmt = Math.min(subtotal * (discount / 100), subtotal);
    //                 } else {
    //                     discountAmt = Math.min(discount, subtotal);
    //                 }



    //                 grandTotal -= discountAmt;



    //                 return {
    //                     ...prevState,
    //                     services: updatedServices,
    //                     walletAmount:amountToDeduct,
    //                     subTotal: formatAmount(subtotal),
    //                     billingTax: formatAmount(totalTax),
    //                     grandTotal: formatAmount(grandTotal),
    //                     discount: discount,
    //                     discountedAmount: formatAmount(discountAmt)
    //                 };
    //             });

    //         }
    //     } catch (error) {
    //         console.log(error);
    //         showToastMessage('error', error.response.data.message);
    //     }
    // };


    const options = [{ value: "", label: "Select Service" }];
    if (services && services.length > 0) {
        const groupedServices = {};
        services.forEach((el) => {
            if (!el.deleted_at) { // Check if deleted_at is null or undefined
                if (!groupedServices[el.service_category_id]) {
                    groupedServices[el.service_category_id] = [];
                }

                groupedServices[el.service_category_id].push({
                    value: el.id,
                    label: el.name,
                    cat_id: el.get_cat_name?.name
                });
            }
        });

        for (const category in groupedServices) {
            options.push({
                label: groupedServices[category][0].cat_id,
                options: groupedServices[category],
            });
        }
    }


    const staffOptions = [{ value: "", label: "Select Staff" }];
    if (staffData && staffData.length > 0) {
        staffData.forEach((el) => {
            if (!el.deleted_at && el.role?.role === 'employee') { // Check if deleted_at is null or undefined and role is 'employee'
                staffOptions.push({
                    value: el.id,
                    label: el.name,
                });
            }
        });
    }



    const handleSubmit = async (e) => {



        if (validateForm()) {

            setBtn(true)

            try {
                const result = await handleAppointment(formData, reason);

                console.log(result);
                if (result.data) {
                    showToastMessage('success', result.data.message)

                    setBtn(false)
                    navigate('/appointment-listing')

                }
            } catch (error) {
                setBtn(false)
                console.log(error);
                if (error.response.status === 404) {
                    const errors = error.response.data.message
                    if (errors && Object.keys(errors).length > 0) {
                        Object.keys(errors).forEach((key) => {
                            errors[key].forEach((message) => {
                                showToastMessage('error', message)
                            });
                        });
                    }
                } else {
                    showToastMessage('error', error.response.data.message)
                }
            }

        }


    }

    return (

        <>

            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center bg-transparent">
                    <div className="p-header d-flex flex-column gap-2 align-items-start justify-content-start">
                        <h2 className="bodytext-30 fontweight-600 text-dark mb-0">Make Appointment</h2>

                    </div>
                    <NavLink to='/appointment-listing' className="button button-primary">View All Appointments</NavLink>
                </div>
                <div className="card-body">
                    <form id="billingform" autoComplete="off">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="phone">Mobile Number *</label>
                                            <p className="form-control">{formData.phone}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Customer">Customer Name *</label>
                                            <p className="form-control">{formData.name}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6" >
                                        <div className="form-group gender_check">
                                            <label htmlFor="gender">Gender</label>
                                            <p className="form-control">{formData.gender}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group gender_check">
                                            <label htmlFor="gender">How did you reach us?</label>
                                            <p className="form-control">{formData.refId}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Customer">Date</label>
                                            <p className="form-control">{formData.date}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Customer">Time</label>
                                            <p className="form-control">{formData.time}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <blockquote className="blockquote blockquote-primary customer_detail round-10 " >
                                    <CustomerDetail
                                        selectedCustomer={selectedCustomer}
                                    />
                                </blockquote>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 margintop-5 marginbottom-15">
                                <button type="button" onClick={addRow} id="btn_add_row" className="button button-primary button-icon-left"><i className="fa fa-plus"></i> Add Service</button>
                            </div>
                            <div className="col-md-12 mt-4">
                                <div className="table-responsive table-no-scroll">
                                    {formData.services.map((value, index) => (
                                        <SelectStaffService
                                            key={index}
                                            index={index}
                                            value={value}
                                            // formData={formData}
                                            services={options} // Pass your options here
                                            staffData={staffOptions} // Pass your staffOptions here
                                            // handleServicecategory={handleServicecategory}
                                            handleFieldChange={handleFieldChange}
                                            removeRow={removeRow}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-between mt-2">
                            <div className="col-12" id="button_add">
                                <div className="form-group">
                                    <label for="billing_remark">Remark</label>
                                    <textarea
                                        className="form-control billing_remark"
                                        id="billing_remark"
                                        name="billingRemark"
                                        value={formData.billingRemark}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                        }}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-12 mt-2" id="package">

                                <button
                                    type="button"
                                    // value="Save Bill &amp; Print" 
                                    onClick={handleSubmit}
                                    disabled={btn}
                                    className="button button-primary" >Save Appointment</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default EditAppointment;
