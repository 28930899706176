import axiosInstance from './axiosConfig';

const handleMembership = async (formData) => {
  try {
    const response = await axiosInstance.post('save-membership', formData);
    console.log(response,'res');
    return response;


  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleEditMembership = async (formData) => {
  try {
    const response = await axiosInstance.post('edit-outstandingbalance', formData);
    console.log(response,'res');
    return response;


  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const modifyRequest = async (formData) => {
  try {
    const response = await axiosInstance.post('modify-request', formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleEditBilling = async (uuid) => {
  try {
    const response = await axiosInstance.post(`edit-membership?uuid=${uuid}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};



const handleMembershipListing = async (orgId,filterData,page,per_page,searchText) => {
  try {
    const response = await axiosInstance.get(`get-membership-listing?orgId=${orgId}&filterData=${filterData}&page=${page}&per_page=${per_page}&searchText=${searchText}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleDeleteMembership = async (uuid, reason) => {
  try {
    const response = await axiosInstance.get(`delete-membership?uuid=${uuid}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleMembershipPDF = async (uuid) => {
  try {
    const response = await axiosInstance.get(`download-membership-pdf?uuid=${uuid}`
    // , {
    //   responseType: 'blob',
    //   headers: {
    //     Accept: 'application/pdf',
    //     'Content-Type': 'application/pdf',
    //   },
    // }
    );
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export {
  handleMembership,
  handleEditMembership,
  handleMembershipListing,
  handleDeleteMembership,
  handleMembershipPDF,
  handleEditBilling,
  modifyRequest,
};