import { useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const ConfirmModal = ({ modalIsOpen, closeModal, customStyles , setUUID , UUID }) => {
  const protectedPassword = 'tditresslounge@123';
  const headerText = 'Confirm Password';
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const confirmPassword = () => {
    if (password === protectedPassword) {
      navigate(`/edit-billing/${UUID}`)
      setUUID('')
    } else {
      setError('Incorrect password. Please try again.');
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError('');  // Clear the error message when the user starts typing
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Confirm Password Modal"
      className="custom-modal round-15 bg-white"
    >
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="mb-0 heading-h4 fontweight-500">{headerText}</h2>
        <button className="btn icon-md round-100 bodytext-22 border-0 icontext-darkgrey" onClick={closeModal}>
          <i className="mdi mdi-close menu-icon"></i>
        </button>
      </div>

      <div className="col-md-6 col-12">
        <div className="form-group">
          <label htmlFor="password" className="form-label">Password:</label>
          <input
            type="password" // Use type password for better security
            className="form-control"
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Password"
          />
          {error && (
            <div className="text-danger">{error}</div>
          )}
        </div>
      </div>

      <div className="col-md-12 col-12 text-end">
        <button
          type="button"
          className="button button-primary button-icon-left"
          onClick={confirmPassword}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
