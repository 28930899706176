// export function discountValidate(e) {
//     // Allow only digits and specific control keys (backspace, delete, arrow keys, etc.)
//     const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Home", "End"];
//     if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
//       e.preventDefault();
//     }

//     // Ensure that the number does not exceed 100
//     const newValue = parseInt(e.target.value + e.key);
//     if (newValue > 100) {
//       e.preventDefault();
//     }

//     // Ensure that only 3 digits can be entered
//     if (e.target.value.length >= 3 && !allowedKeys.includes(e.key)) {
//       e.preventDefault();
//     }
//   }
export function discountValidate(e) {
  const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Home", "End"];
  const currentSymbol = document.getElementById("discount_mode").value;
  const currentValue = e.target.value;

  // Allow only digits and specific control keys (backspace, delete, arrow keys, etc.)
  if (!/\d/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }

  // Get the new value after the keypress
  const newValue = parseInt(currentValue + e.key);

  if (currentSymbol === '%') {
    // Ensure that the number does not exceed 100 for percentage discount
    if (newValue > 100) {
      e.preventDefault();
      e.target.value = "100"; // Auto-fix the value to 100
    }

    // Ensure that only 3 digits can be entered for percentage discount
    if (currentValue.length >= 3 && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  } else {
    // Check if switching from rupee to percentage and reset validation
    const isSwitchingToPercentage = currentValue === '₹' && e.key === '%';

    if (isSwitchingToPercentage) {
      e.target.value = ""; // Reset the value when switching to %
      return; // Allow switching without further validation
    }

    // Ensure that the number does not exceed half of the subtotal for rupee discount
    const subtotal = parseFloat(document.getElementById("subTotal").value);
    if (newValue > subtotal / 2) {
      e.preventDefault();
    }
  }
}




