import axiosInstance from './axiosConfig';

const handleAppointment = async (formData,reason) => {
  try {
    const response = await axiosInstance.post(`save-appointment?reason=${reason}`, formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleEditAppointment = async (uuid) => {
  try {
    const response = await axiosInstance.post('edit-appointment', uuid);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleCustomerSearch = async (formData, orgId) => {
  try {
    const response = await axiosInstance.get(`get-customer-search?value=${formData}&orgId=${orgId}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleAppointmentListing = async (orgId, status,page,per_page,searchText) => {
  try {
    const response = await axiosInstance.get(`get-appointment-listing?orgId=${orgId}&status=${status}&page=${page}&per_page=${per_page}&searchText=${searchText}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleDeleteAppointment = async (uuid,reason) => {
  try {
    const response = await axiosInstance.get(`delete-appointment?uuid=${uuid}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleAppointmentPDF = async (uuid) => {
  try {
    const response = await axiosInstance.get(`download-pdf?uuid=${uuid}`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/pdf',
      },
    });
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export {
  handleAppointment,
  handleEditAppointment,
  handleCustomerSearch,
  handleAppointmentListing,
  handleDeleteAppointment,
  handleAppointmentPDF,
};
