import Modal from 'react-modal';
import  { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';


Modal.setAppElement('#root')


const IframeModal = ({ modalIsOpen, closeModal, customStyles, setIsOpen,layout,modalHeading}) => {

    const contentToPrint = useRef(null);
   
    const headerText =  modalHeading ? modalHeading : 'Billing';

    const handlePrint = useReactToPrint({

      onBeforePrint: () => console.log("before printing..."),
      onAfterPrint: () => console.log("after printing..."),
      removeAfterPrint: true,

    });
  
  return <>
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      setIsOpen={setIsOpen}
      shouldCloseOnOverlayClick={false}
      contentLabel="Example Modal"
      className="custom-modal round-15 bg-white invoice-billing"
    >   
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mb-0 heading-h4 fontweight-500">{headerText}</h2>
          <button className="bnt icon-md round-100 bodytext-22 border-0 icontext-darkgrey" onClick={closeModal}><i class="mdi mdi-close menu-icon"></i></button>
        </div>

        <div ref={contentToPrint}>
        {layout}
        </div>
      
          <div className="col-md-12 col-12 py-3 text-center">
                <button type="button" 
                className="button button-primary print-btn"
                onClick={() => {
                  handlePrint(null, () => contentToPrint.current);
                }}
              >
                Print Invoice
              </button>
            </div> 
      
    </Modal>
  </>
}

export default IframeModal;
