export function roundOffAmount(amount) {

    const roundValue = 5;
    // Check if amount is a valid number
    if (isNaN(amount)) {
      return 'Invalid Amount';
    }
  
    // Check if roundValue is a valid number
    if (isNaN(roundValue) || roundValue <= 0) {
      return 'Invalid Round Value';
    }
  
    // Round off amount to nearest multiple of roundValue
    const roundedAmount = Math.round(amount / roundValue) * roundValue;
  
    return roundedAmount;
  }

// export function roundOffAmount(amount) {
//   // Check if amount is a valid number
//   if (isNaN(amount)) {
//       return 'Invalid Amount';
//   }

//   // Round off amount to the nearest integer
//   const roundedAmount = Math.round(amount);

//   return roundedAmount;
// }

// export function roundOffAmount(amount) {
//   const roundValue = 5;

 
//   if (isNaN(amount)) {
//       return 'Invalid Amount';
//   }

//   if (isNaN(roundValue) || roundValue <= 0) {
//       return 'Invalid Round Value';
//   }

//   // Round off amount to nearest multiple of roundValue
//   const roundedAmount = Math.floor(amount / roundValue) * roundValue;

//   return roundedAmount;
// }

