

const checkPermission = (data,permission) => {

    // console.log(data?.permissions);
    
    const hasPermission = data?.permissions?.some(i => i.name === permission);
    return hasPermission;
};

export default checkPermission;


