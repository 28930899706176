import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import WhatsappIntegration from '../../components/Setting/WhatsappIntegration';
import Organisation from '../../components/Setting/Organisation';
import AdditionalSettings from '../../components/Setting/AdditionalSettings';
// import ProfileLayout from '../../components/Profile/ProfileLayout';


const Setting = ({selectGlobalOrg}) => {

    const [key, setKey] = useState('organisation');

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3 custom-head-bg"
    >
      <Tab eventKey="organisation" title="Organisation">
      <Organisation selectGlobalOrg={selectGlobalOrg}/>
      </Tab>
   
      <Tab eventKey="whatsapp-integration" title="Whatsapp Integration" >
     
      <WhatsappIntegration selectGlobalOrg={selectGlobalOrg}/>
      </Tab>

      <Tab eventKey="additional-settings" title="Additional Settings" >
     
      <AdditionalSettings selectGlobalOrg={selectGlobalOrg}/>
      </Tab>

     
    </Tabs>
  );

}

export default Setting;



