import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { showToastMessage } from '../../helpers/toastr';
import { handleProductBrandListing } from '../../services/productBrandService';
import { handleProduct } from '../../services/productService';
import { formatIntger } from '../../helpers/formatIntger';
Modal.setAppElement('#root')


const ProductModal = ({ modalIsOpen, closeModal, customStyles, type, setIsOpen, selectGlobalOrg, fetchData, selectedProduct }) => {

    const [options, setOptions] = useState([]);

    const [btn, setBtn] = useState(false);

    const headerText = type === "add" ? 'Add Product' : 'Edit Product';

    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        name: '',
        // reminder: '',
        price: '',
        productID: '',
        orgId: '',
        type: '',
    });

    useEffect(() => {

        if (modalIsOpen === true) {
            fetchProduct()
        }

        if (type === 'edit' && selectedProduct) {
            setFormData({
                name: selectedProduct.name,
                reminder: selectedProduct.reminder,
                price: selectedProduct.price,
                productID: selectedProduct.product_id,
                dob: selectedProduct.dob,
                orgId: selectedProduct.org_id,
                id: selectedProduct.id,
                status: selectedProduct.status == 0 ? true : false,
                type: type,
            });
        } else {
            const newOrgId = document.getElementById('orgId').value;
            setFormData({
                name: '',
                // reminder: '',
                price: '',
                productID: '',
                orgId: newOrgId,
                status: true,
                type: type,
            });
        }

        setErrors({});

    }, [modalIsOpen]);

    useEffect(() => {

        if (modalIsOpen === true) {
            fetchProduct()
            const newOrgId = document.getElementById('orgId').value;
            setFormData((prevFormData) => ({
                ...prevFormData,
                ['productID']: "",
                ['orgId']: newOrgId,
            }));
        }

    }, [selectGlobalOrg]);

    const fetchProduct = async () => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

        const type = '0';

        try {
            const result = await handleProductBrandListing(initialOrgIds, type);
            if (result.data) {
                setOptions(result.data.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }


    }

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = "Product name is required";
            isValid = false;
        }

        const productID = String(formData.productID).trim();
        if (!productID) {
            newErrors.productID = "Product brand is required";
            isValid = false;
        }

        // Validate phone
        const price = String(formData.price).trim();
        if (!price) {
            newErrors.price = "Product price is required";
            isValid = false;
        }

        const reminder = String(formData.reminder).trim();
        if (!reminder) {
            newErrors.reminder = "Product reminder is required";
            isValid = false;
        }


        setErrors(newErrors);
        return isValid;

    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const processedValue = type === 'number' ? parseFloat(value) : value;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: processedValue,
        }));

        if (name === 'status') {

            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: checked
            }));
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    const saveProduct = async (type) => {


        if (validateForm()) {

            setBtn(true);

            try {
                const reason=selectedProduct.reason
                const result = await handleProduct(formData,reason);

                console.log(result, 'result');

                if (result.data) {
                    showToastMessage('success', result.data.message)
                    fetchData()
                    closeModal();
                    setBtn(false);
                }

            } catch (error) {
                console.log(error);
                setBtn(false);
                showToastMessage('error', error.response.data.message)
            }



        }
    };

    return < >
        <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      setIsOpen={setIsOpen}
      shouldCloseOnOverlayClick={false}
      contentLabel="Example Modal"
      className="custom-modal round-15 bg-white"
    >
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mb-0 heading-h4 fontweight-500">{headerText}</h2>
          <button className="bnt icon-md round-100 bodytext-22 border-0 icontext-darkgrey" onClick={closeModal}><i class="mdi mdi-close menu-icon"></i></button>
      </div>
      <form className="mx-auto form mt-4" style={{ maxWidth: '100%' }}>
        <div className="row">
           <div className="col-md-6 col-12">
              <div className="form-group">
                 <label htmlFor="name" className="form-label">Product Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Enter your Product name"
                  />
                  {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}
              </div>
            </div>
             <div className="col-md-6 col-12">
                <div className="form-group">
                  <label htmlFor="price" className="form-label">Product Price:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="price"
                    name="price"
                    value={formData.price}
                    onKeyDown={(e) => formatIntger(e)}
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Enter your price."
                  />
                  {errors.price && (
                    <div className="text-danger">{errors.price}</div>
                  )}
                </div>
            </div>
             <div className="col-md-6 col-12">
              <div className="form-group">
                  <label htmlFor="productID" className="form-label">Product  Brand:</label>
                  <select
                    className="form-control form-select"
                    id='productID'
                    style={{ paddingRight: '40px' }}
                    name='productID'
                    value={formData.productID}
                    onChange={(e) => handleInputChange(e)}
                  >
                     <option value="">Select Product Brand</option>
                     {options && options.length > 0 ? options.map((el,index) => (
                        <option key={index} value={el.id}>{el.name}</option>
                     )):''}
                  </select>
                  {errors.productID && (
                    <div className="text-danger">{errors.productID}</div>
                  )}
              </div>
            </div>
             {/* <div className="col-md-6 col-12">
                <div className="form-group">
                   <label htmlFor="reminder" className="form-label">Product Reminder:</label>
                    <input
                      type="number"
                      className="form-control"
                      id="reminder"
                      name="reminder"
                      value={formData.reminder}
                      onChange={(e) => handleInputChange(e)}
                      placeholder="After how many days"
                    />
                    {errors.reminder && (
                      <div className="text-danger">{errors.reminder}</div>
                    )}
                </div>
              </div> */}
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label htmlFor="status" className="form-label">Status:</label>
                    <div class="form-check">
                        
                        <input
                          type='checkbox'
                          className="form-check"
                          id='status'
                          name='status'
                          value={formData.status}
                          checked={formData.status}
                          onChange={(e) => handleInputChange(e)}
                        />
                        <label for="status">
                            <span class="checkbox">
                                <i class="mdi mdi-check menu-icon"></i>
                            </span>
                        </label>
                    </div>  
                </div>
              </div>
               <div className="col-md-12 col-12 text-end">
                   <button type="button" disabled={btn}
                    className="button button-primary"
                    onClick={() => saveProduct(type)}
                  >
                    Submit
                  </button>
               </div>
        </div>
      </form>
    </Modal>
    </>
}

export default ProductModal;