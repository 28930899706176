// Define global array
var paymentOptions = [
    { value: "cash", name: "Cash" },
    { value: "cc", name: "Card" },
    { value: "pkg", name: "Package" },
    { value: "gpay", name: "Gpay/UPI" },
    { value: "upibills", name: "API" }
];


// Function to return name based on value
export function paymentOption(value) {
    for (var i = 0; i < paymentOptions.length; i++) {
        if (paymentOptions[i].value === value) {
            return paymentOptions[i].name;
        }
    }
    return null; 
}

export function selectOptions(type) {

    const array = type === 'deleteOption' ? paymentOptions.filter((option) => option.value !== 'pkg' ) : paymentOptions;

    return array.map((option) => (
        <option key={option.value} value={option.value}>
            {option.name}
        </option>
    ));
}


