import styles from "./styles";

const ProductInvoice = ({data}) => {

    console.log(data,'{data}');

    return (
        <>
   <div className="invoice">
      <div className="header">
        <h1>Invoice</h1>
        {(data.date || data.time) && (
          <p>Date: {data.date || ''} {data.time || ''}</p>
        )}
      </div>

      {data.get_org_info?.gst_no && data.get_org_info?.legal_name && (
        <div className="gst-details">
          <div>
            <span><strong>GST No:</strong> {data.get_org_info.gst_no}</span>
          </div>
          <div>
            <span><strong>Legal Name:</strong> {data.get_org_info.legal_name}</span>
          </div>
        </div>
      )}

      <div className="details">
        <p>Bill No: {data.bill_no}</p>
      </div>

      <div className="details">
        <p>Bill To:</p>
        <p>{data.get_customer_info?.name}</p>
        <p>{data.get_customer_info?.phone_number}</p>
      </div>

      <table className="invoice-table prod-list">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {data?.get_multiple_products.map((item, index) => (
            <tr key={index}>
              <td>{item?.get_product_name?.name || ''}</td>
              <td>{item.quantity}</td>
              <td>{item.price}</td>
              <td>{item.total}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="3">Subtotal</td>
            <td>{data.sub_total}</td>
          </tr>
          <tr>
            <td colSpan="3">Payment Mode</td>
            <td>{data.payment_method}</td>
          </tr>
        </tfoot>
      </table>
    </div>
        
        </>
    )
   

}

export default ProductInvoice;