import DataTable from "react-data-table-component";
// import wrapServiceCategoryfn from "../../../components/service/serviceCategory/ServiceCategoryModal.js";
import ServiceCategoryModal from "../../../components/service/serviceCategory/ServiceCategoryModal.jsx";
import { useState, useEffect } from "react";
import { showToastMessage } from "../../../helpers/toastr.js";
import { handleDeleteServiceCategory, handleEditServiceCategory, handleServiceCategoryListing } from "../../../services/serviceCategory.js";
import wrapModalfn from "../../../components/modal/modal.js";
import Loading from "../../../components/loader/Loading.jsx";
import ConfirmationPopupButton from "../../../components/ConfirmationPopup/ConfirmationPopupButton.jsx";
import ConfirmationPopup from "../../../components/ConfirmationPopup/ConfirmationPopup.jsx";
import SearchFilter from "../../../components/pagination/SearchFilter.jsx";
import { debounce } from 'lodash';
import { useSelector } from "react-redux";
import checkPermission from "../../../helpers/checkPermission.js";
import showDeletePopup from "../../../helpers/showDeletePopup.js";
import { conditionalRowStyles } from "../../../helpers/conditionalRowStyles.js";
import { handleGetOtp } from "../../../services/billingService.js";
import showOtpVerficationPopup from "../../../helpers/showOtpVerficationPopup.js";
import { handleOtpVerfication } from "../../../services/billingService.js";
import showEditPopup from "../../../helpers/showEditPopup.js";

const ServiceCategory = ({ selectGlobalOrg }) => {
    const userData = useSelector((state) => state.userData);

    const [popupID, setPopupID] = useState('');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const { customStyles, openModal, closeModal, setIsOpen, type, modalIsOpen } = wrapModalfn();
    const [perPage, setPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const hasAdminRole = userData?.role?.role === 'admin' ? true : false;
    const hasCoOwnerRole = userData?.role?.role === 'co-owner' ? true : false;


    const handlePageChange = page => {
        fetchData(page, perPage, searchValue);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage, searchValue);
    };



    //     useEffect(() => {

    //         fetchData();

    // }, [selectGlobalOrg])
    useEffect(() => {
        // if (filterData != undefined) {
        //     const parsedFilterData = JSON.parse(filterData);
        //     if (parsedFilterData.length === 0) {
        //         return;
        //     }
        // }

        const debouncedFetchData = debounce(fetchData, 300);
        const fetchDataOnChange = () => {
            debouncedFetchData(1, perPage, searchValue);
        };

        if (searchValue) {
            fetchDataOnChange();
        } else {
            fetchData(1, perPage, searchValue);
        }

        return () => {
            debouncedFetchData.cancel();
        };
    }, [selectGlobalOrg, perPage, searchValue]);

    const fetchData = async (page, per_page, searchText) => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

        try {
            const result = await handleServiceCategoryListing(initialOrgIds, page, per_page, searchText);
            if (result.data) {
                setData(result.data.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        } finally {
            setLoading(false)
        }

    }

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            sortFunction: (a, b) => a.name.localeCompare(b.name),
        },
    ];
    if (hasAdminRole) {
        columns.push({
            name: 'Reason',
            selector: row => row?.has_latest_delete_request?.length > 0 ? row?.has_latest_delete_request[0].reason : '',
            sortable: true,
        });
    }
    columns.push({
        name: 'Actions',
        cell: row => (
            <div className="d-flex gap-2">
                 {checkPermission(userData, 'Update service-category') && (
                    <button
                        className="icon-sm-medium round-100 icontext-blue borderwidth-0 bg-white"
                        onClick={() => {
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record is deleted, you cannot edit it.');
                            } else {
                                handleEdit(row.id, 'edit');
                            }
                        }}
                    >
                        <i className="mdi mdi-lead-pencil menu-icon bodytext-24"></i>
                    </button>
                )}
               
                {checkPermission(userData, 'Delete service-category') && (
                    <ConfirmationPopupButton
                        handleDelete={() =>{
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record already is deleted, you cannot delete it.');
                            } else {
                                handleDelete(row.id)
                            }
                        } }

                    />
                )}

                {/* Add more action buttons as needed */}
            </div>
        ),
    });

    const handleDelete = async (id) => {
        const reason = await showDeletePopup();
        if (reason === '' || reason == null) {
            showToastMessage("error", "Reason for deletion is required!");
            return;
        }
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleDeleteServiceCategory(id, reason);
                    if (result.data) {
                        showToastMessage('success', result.data.message);
                        fetchData(currentPage, perPage)
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            }
        } else {
            const requestType = 'delete';
            const result = await handleGetOtp(id, reason, requestType);
            while (true) {
                let otp = await showOtpVerficationPopup('info');
                if (otp !== '' && otp !== null) {
                    try {
                        const otpResult = await handleOtpVerfication(id, otp, reason);
                        showToastMessage('success', 'OTP verified successfully');
                        try {
                            await handleDeleteServiceCategory(id, reason);
                            fetchData(currentPage, perPage)
                        } catch (error) {
                            console.log(error);
                            showToastMessage('error', error.response.data.message)
                        }
                        break; // Exit the loop if everything is successful
                    } catch (error) {
                        if (error && error.response && error.response.data && error.response.data.error) {
                            showToastMessage('error', error.response.data.error);
                        } else {
                            showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                        }
                        console.error('Error:', error);
                        otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                    }
                } else {
                    showToastMessage('info', 'Edit operation was cancelled.');
                    break; // Exit the loop if the operation is cancelled
                }
            }
        }



    };

    const handleEdit = async (id, type) => {
        const reason = await showEditPopup();
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleEditServiceCategory(id);
                    if (result.data) {
                        setSelectedCategory(result.data?.data)
                        setSelectedCategory(preCategory => ({
                            ...preCategory,
                            reason: reason,
                        }));
                        openModal(type)
                    }

                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message)
                }
            } else {

            }
        } else {
            if (reason !== '' && reason !== null) {
                try {
                    const requestType = 'edit';
                    const result = await handleGetOtp(id, reason, requestType);
                    while (true) {
                        let otp = await showOtpVerficationPopup('info');
                        if (otp !== '' && otp !== null) {
                            try {
                                const otpResult = await handleOtpVerfication(id, otp, reason);
                                showToastMessage('success', 'OTP verified successfully');
                                try {
                                    const result = await handleEditServiceCategory(id);

                                    if (result.data) {
                                        setSelectedCategory(result.data?.data)
                                        setSelectedCategory(preCategory => ({
                                            ...preCategory,
                                            reason: reason,
                                        }));
                                        openModal(type)
                                    }

                                } catch (error) {
                                    console.log(error);
                                    showToastMessage('error', error.response.data.message)
                                }
                                break; // Exit the loop if everything is successful
                            } catch (error) {
                                if (error && error.response && error.response.data && error.response.data.error) {
                                    showToastMessage('error', error.response.data.error);
                                } else {
                                    showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                                }
                                console.error('Error:', error);
                                otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                            }
                        } else {
                            showToastMessage('info', 'Edit operation was cancelled.');
                            break; // Exit the loop if the operation is cancelled
                        }
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', 'An unexpected error occurred while requesting OTP.');
                }
            } else {
                showToastMessage('info', 'Edit operation was cancelled.');
            }
        }
    };

    return <>
        <div className="row">

            {/* <ConfirmationPopup 
               handleDelete={handleDelete} 
               popupID={popupID}
               setPopupID={setPopupID}
               /> */}

            <div className="col-lg-12 grid-margin">
                <div className="card">
                    <div className="card-header bg-transparent">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                            <h4 class="card-title heading-h3 mb-0">Services Category</h4>
                            {checkPermission(userData, 'Create service-category') && (
                                <button type="button"
                                    onClick={() => openModal('add')}
                                    className="button button-primary button-icon-left mt-2">
                                    <i class="fa fa-plus-circle"></i> Add Service Category
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="card-body">
                        <ServiceCategoryModal
                            modalIsOpen={modalIsOpen}
                            setIsOpen={setIsOpen}
                            closeModal={closeModal}
                            customStyles={customStyles}
                            fetchData={(e) => fetchData(currentPage, perPage)}
                            type={type}
                            selectedCategory={selectedCategory}

                        />
                        <div className="row">
                            {loading ? (
                                <Loading />
                            ) : (
                                <>
                                    <DataTable
                                        columns={columns}
                                        data={data?.data}
                                        pagination
                                        paginationPerPage={25}
                                        paginationServer
                                        paginationTotalRows={data?.total}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        subHeader
                                        subHeaderComponent={<SearchFilter setSearchValue={setSearchValue} searchValue={searchValue} />}
                                        paginationComponentOptions={{
                                            rangeSeparatorText: 'of',
                                            noRowsPerPage: true,
                                        }}
                                        {...(hasAdminRole && { conditionalRowStyles })}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

}

export default ServiceCategory;