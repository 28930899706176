import axiosInstance from "./axiosConfig";

const handleExpenseCategory = async (formData,reason) => {
  try {
    const response = await axiosInstance.post(`save-expense-category?reason=${reason}`, formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleExpenseCategoryListing = async (formData,page,per_page,searchText) => {
  try {
    const response = await axiosInstance.get(`get-expense-category-listing?orgId=${formData}&page=${page}&per_page=${per_page}&searchText=${searchText}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleEditExpenseCategory = async (id) => {
  try {
    const response = await axiosInstance.get(`edit-expense-category?id=${id}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleDeleteExpenseCategory = async (id,reason) => {
  try {
    const response = await axiosInstance.get(`delete-expense-category?id=${id}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export {
  handleExpenseCategory,
  handleExpenseCategoryListing,
  handleEditExpenseCategory,
  handleDeleteExpenseCategory
};