const Error403 = () => {

    return (

        <>

<div className="row">
    
             {/* <ConfirmationPopup 
               handleDelete={handleDelete} 
               popupID={popupID}
               setPopupID={setPopupID}
               /> */}

            <div className="col-lg-12 grid-margin">
                <div className="card">
                {/* <div className="card-header bg-transparent">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 class="card-title heading-h3 mb-0">Services</h4>
                             <button type="button"
                                onClick={() => openModal('add')}
                                className="button button-primary button-icon-left">
                                <i class="fa fa-plus-circle"></i> Add Service
                            </button>
                        </div>   
                    </div> */}
                    <div className="card-body">
                        <div className="row">
                        <h1>403 Forbidden</h1>
                          <p>Sorry, you don't have permission to access this page.</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </>

    )

    
    
}

export default Error403;