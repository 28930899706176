
export const Listing = ({searchdata,handleCustomerClick}) => {

    return <>

            {searchdata?.map((el,index) => (
                <li onClick={() => handleCustomerClick(el.uuid) } key={index} data-value={el.phone_number} className="active">
                <span>{el.phone_number+' - '+el.name}</span>
            </li>
             ) )}

          </>

}