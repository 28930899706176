
const collapseSidebar = (value, type) => {
    console.log(value, type);
    if (type === 'body') {
      document.body.className = document.body.className === value ? '' : value;
    } else {
      const sidebar = document.getElementById('sidebar');
      sidebar.className = sidebar.className === value ? 'sidebar sidebar-offcanvas' : value;
    }
  };

export default collapseSidebar;