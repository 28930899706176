import React, { useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

export default function ConfirmationPopup({handleDelete,popupID,setPopupID}) {
    const toast = useRef(null);

    const accept = () => {
        handleDelete(popupID);
        setPopupID('');
    };

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog 
                group="headless"
                content={({ headerRef, contentRef, footerRef, hide, message   }) => (

                    <div className="flex flex-column align-items-center p-5 surface-overlay border-round">
                        <div className="border-circle bg-red text-white inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                            <i className="mdi mdi-delete-empty menu-icon bodytext-24"></i>
                        </div>
                        <span className="font-bold text-2xl block mb-2 mt-4" ref={headerRef}>
                            {message.header}
                        </span>
                        <p className="mb-0" ref={contentRef}>
                            {message.message}
                        </p>
                        <div className="flex align-items-center gap-2 mt-4" ref={footerRef}>
                            <Button
                                label="Delete"
                                onClick={(event) => {
                                    hide(event);
                                    accept();
                                }}
                                className="button button-primary"
                            ></Button>
                            <Button
                                label="Cancel"
                                outlined
                                onClick={(event) => {
                                    hide(event);
                                    // reject();
                                }}
                                className="button borderwidth-1 bordercolor-light text-dark"
                            ></Button>
                        </div>
                    </div>
                )}
            />
           
        </>
    )
}
        