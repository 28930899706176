import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { showToastMessage } from '../../../helpers/toastr';
import { handleServiceCategoryListing } from '../../../services/serviceCategory.js';
import { handleService } from '../../../services/service.js';
import { formatIntger } from '../../../helpers/formatIntger.js';
Modal.setAppElement('#root')


const ServiceModal = ({ modalIsOpen, closeModal, customStyles, type, setIsOpen, selectGlobalOrg, fetchData, selectedService }) => {

    const [options, setOptions] = useState([]);

    const [btn, setBtn] = useState(false);

    const headerText = type === "add" ? 'Add Service' : 'Edit Service';

    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        name: '',
        reminder: '',
        price: '',
        categoryID: '',
        orgId: '',
        type: '',
    });

    useEffect(() => {

        if (modalIsOpen === true) {
            fetchServiceCategory()
        }

        if (type === 'edit' && selectedService) {
            setFormData({
                name: selectedService.name,
                reminder: selectedService.reminder,
                price: selectedService.price,
                categoryID: selectedService.service_category_id,
                dob: selectedService.dob,
                orgId: selectedService.org_id,
                id: selectedService.id,
                status: selectedService.status == 0 ? true : false,
                type: type,
            });
        } else {
            const newOrgId = document.getElementById('orgId').value;
            setFormData({
                name: '',
                reminder: '',
                price: '',
                categoryID: '',
                orgId: newOrgId,
                status: true,
                type: type,
            });
        }

        setErrors({});

    }, [modalIsOpen]);

    useEffect(() => {

        if (modalIsOpen === true) {
            fetchServiceCategory()
            const newOrgId = document.getElementById('orgId').value;
            setFormData((prevFormData) => ({
                ...prevFormData,
                ['categoryID']: "",
                ['orgId']: newOrgId,
            }));
        }

    }, [selectGlobalOrg]);

    const fetchServiceCategory = async () => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

        const type = '0';

        try {
            const result = await handleServiceCategoryListing(initialOrgIds, type);
            if (result.data) {
                setOptions(result.data.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }


    }

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = "Service name is required";
            isValid = false;
        }

        const categoryID = String(formData.categoryID).trim();
        if (!categoryID) {
            newErrors.categoryID = "Service category name is required";
            isValid = false;
        }

        // Validate phone
        const price = String(formData.price).trim();
        if (!price) {
            newErrors.price = "Service price is required";
            isValid = false;
        }

        const reminder = String(formData.reminder).trim();
        if (!reminder) {
            newErrors.reminder = "Service reminder is required";
            isValid = false;
        }


        setErrors(newErrors);
        return isValid;

    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const processedValue = type === 'number' ? parseFloat(value) : value;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: processedValue,
        }));

        if (name === 'status') {

            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: checked
            }));
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    const saveService = async (type) => {


        if (validateForm()) {

            setBtn(true);

            try {
                const reason = selectedService.reason;
                const result = await handleService(formData, reason);

                if (result.data) {
                    showToastMessage('success', result.data.message)
                    fetchData()
                    closeModal();
                    setBtn(false);
                }

            } catch (error) {
                console.log(error);
                setBtn(false);
                showToastMessage('error', error.response.data.message)
            }



        }
    };

    return < >
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            setIsOpen={setIsOpen}
            shouldCloseOnOverlayClick={false}
            contentLabel="Example Modal"
            className="custom-modal round-15 bg-white"
        >
            <div className="d-flex align-items-center justify-content-between">
                <h2 className="mb-0 heading-h4 fontweight-500">{headerText}</h2>
                <button className="bnt icon-md round-100 bodytext-22 border-0 icontext-darkgrey" onClick={closeModal}><i class="mdi mdi-close menu-icon"></i></button>
            </div>
            <form className="mx-auto form mt-4" style={{ maxWidth: '100%' }}>
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="name" className="form-label">Service Name:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={(e) => handleInputChange(e)}
                                placeholder="Enter your service name"
                            />
                            {errors.name && (
                                <div className="text-danger">{errors.name}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="price" className="form-label">Service Price:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="price"
                                name="price"
                                onKeyDown={formatIntger}
                                value={formData.price}
                                onChange={(e) => handleInputChange(e)}
                                placeholder="Enter your price."
                            />
                            {errors.price && (
                                <div className="text-danger">{errors.price}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="categoryID" className="form-label">Service Category Name:</label>
                            <select
                                className="form-control form-select"
                                id='categoryID'
                                style={{ paddingRight: '40px' }}
                                name='categoryID'
                                value={formData.categoryID}
                                onChange={(e) => handleInputChange(e)}
                            >
                                <option value="">Select Service Category</option>
                                {options && options.length > 0 ?
                                    options
                                        .filter(el => el.deleted_at === null)
                                        .map((el, index) => (
                                            <option key={index} value={el.id}>{el.name}</option>
                                        ))
                                    : ''
                                }

                            </select>
                            {errors.categoryID && (
                                <div className="text-danger">{errors.categoryID}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="reminder" className="form-label">Service Reminder:</label>
                            <input
                                type="number"
                                className="form-control"
                                id="reminder"
                                name="reminder"
                                value={formData.reminder}
                                onChange={(e) => handleInputChange(e)}
                                placeholder="After how many days"
                            />
                            {errors.reminder && (
                                <div className="text-danger">{errors.reminder}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="status" className="form-label">Status:</label>
                            <div class="form-check">

                                <input
                                    type='checkbox'
                                    className="form-check"
                                    id='status'
                                    name='status'
                                    value={formData.status}
                                    checked={formData.status}
                                    onChange={(e) => handleInputChange(e)}
                                />
                                <label for="status">
                                    <span class="checkbox">
                                        <i class="mdi mdi-check menu-icon"></i>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-12 text-end">
                        <button type="button" disabled={btn}
                            className="button button-primary"
                            onClick={() => saveService(type)}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    </>
}

export default ServiceModal;