import { formatDate } from "../../helpers/formatDate";
import { getCurrentDate } from "../../helpers/getCurrentDate";
import styles from "./styles";

const ClientJobCard = ({data}) => {

    const loopItrate = new Array(15).fill(null);

    // console.log(loopItrate);

    console.log(data,'data');

    const date = getCurrentDate();

    // console.log(date,'date');

    return (

        <>

     <div className="invoice"  style={styles.invoice}>
      <table style={styles.width}>
        <tr>
          <td>
            <table style={styles.width}>
              <tr>
                <td style={styles.textCenter}> <h1 className="heading" style={styles.heading}>Tress Lounge</h1></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td className="borderb" style={{ ...styles.paddingt, ...styles.paddingb }}>
            <table style={styles.width}>
              <tr>
                <td style={{width: '60%'}}>
                  <p className="billno" style={styles.billno}>Client Name: {data.get_customer_info?.name}</p>
                </td>
                <td style={{width: '40%'}}>
                  <p className="billno" style={styles.billno}>Bill No: {data.get_customer_info?.name}</p>
                </td>
              </tr>
            </table>
            <table style={styles.width}>
              <tr>
                <td style={{width: '60%'}}>
                  <p className="billno" style={styles.billno}>Ph No: {data.get_customer_info?.phone_number}</p>
                </td>
                <td style={{width: '40%'}}>
                  <p className="billno" style={styles.billno}>Date: {formatDate(data.created_at)}</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table className="invoice-table" style={styles.width}>
                <thead className="theadbg"style={styles.theadbg}>
                  <tr>
                    <th className="theadth"style={{width: "50%", ...styles.theadth, ...styles.textLeft}}>Service</th>
                    <th className="theadth"style={{ width: "50%", ...styles.theadth, ...styles.textLeft }}>Staff</th>
                  </tr>
                </thead>
                <tbody>
                    
                    {data?.get_multiple_services?.map((item, index) => (
                    <tr key={index} className="" style={styles.borderb}>
                        <td className="theadtd" style={{width: "50%", height: "25px", ...styles.theadtd, ...styles.textLeft }}>{item?.get_service_name?.name || ''}</td>
                       <td className="theadtd"style={{ width: "50%", height: "25px", ...styles.theadtd, ...styles.textLeft }}>{item?.get_staff_name?.name}</td>
                      
                    </tr>
                  ))}
                </tbody>
              </table>
              <table style={styles.width}>
                <tr>
                  <td style={{...styles.theadth}}>
                    <p>I am fully setisfied with the service offered to me and have no complaint what so ever <span>Tress Lounge</span></p>
                  </td>
                  <td style={styles.theadth}></td>
                </tr>
              </table>
              <table style={styles.width}>
                <tr>
                  <td style={{width: '50%',textAlign: 'left', ...styles.theadth}}>
                    <p>Client Sign</p>
                  </td>
                  <td style={styles.theadth}></td>
                </tr>
              </table>
          </td>
        </tr>
      </table>
      </div>


        </>

    );

}

export default ClientJobCard;