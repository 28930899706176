import { formatDate } from "../../helpers/formatDate";
import { getCurrentDate } from "../../helpers/getCurrentDate";
import styles from "./styles";
import Form from 'react-bootstrap/Form';


const EmployeeJobCard = ({data}) => {

    const loopItrate = new Array(15).fill(null);

    // console.log(loopItrate);

    console.log(data,'data');

    const date = getCurrentDate();

    // console.log(date,'date');

    return (
        <>

    <div className="invoice"  style={styles.invoice}>
      <table style={styles.width}>
        <tr>
          <td>
            <table style={styles.width}>
              <tr>
                <td style={styles.textCenter}> <h1 className="heading" style={styles.heading}>Tress Lounge</h1></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td className="borderb" style={{ ...styles.paddingt, ...styles.paddingb }}>
            <table style={styles.width}>
              <tr>
                <td>
                  <p className="billno" style={styles.billno}>Employee: {data?.name}</p>
                </td>
              </tr>
            </table>
            <table style={styles.width}>
              <tr>
                <td style={{width: '50%'}}>
                  <p className="billno" style={styles.billno}>In time:</p>
                </td>
                <td style={{width: '50%'}}>
                  <p className="billno" style={styles.billno}>Out time:</p>
                </td>
              </tr>
            </table>
            <table style={styles.width}>
              <tr>
                <td style={{width: '60%'}}>
                  <p className="billno" style={styles.billno}>Desgination: {data?.role?.role}</p>
                </td>
                <td style={{width: '40%'}}>
                  <p className="billno" style={styles.billno}>Date: {formatDate(date)}</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table className="invoice-table" style={styles.width}>
                <thead className="theadbg"style={styles.theadbg}>
                  <tr>
                    <td className="theadtd" style={{width: "3.33%", ...styles.theadtd}}></td>
                    <th className="theadth"style={{width: "32.33%", ...styles.theadth}}>Service</th>
                    <th className="theadth"style={{ width: "32.33%", ...styles.theadth, ...styles.textLeft }}>Upgrade</th>
                    <th className="theadth"style={{ width: "32.33%", ...styles.theadth, ...styles.textLeft }}>Amount</th>
                  </tr>
                </thead>
                <tbody>

                {/* {
            loopItrate.map((item, index) => {

                console.log(item, index);

                if (index === item) { 
                return (
                    <>
                    <tr className="" style={styles.borderb}>
                        <td className="theadtd" style={{ width: "3.33%", ...styles.theadtd }}>1</td>
                        <td className="theadtd" style={{ width: "32.33%", ...styles.theadtd }}>rthrth</td>
                        <td className="theadtd" style={{ width: "32.33%", ...styles.theadtd, ...styles.textLeft }}>ertj</td>
                        <td className="theadtd" style={{ width: "32.33%", ...styles.theadtd, ...styles.textLeft }}>ertj</td>
                    </tr>
                    </>
                );
                } else {
                return null; // Or optionally render something else for when the condition is false
                }
            })
            } */}


            {loopItrate.map((_,index) => (
            <tr key={index} className="" style={styles.borderb}>
                <td className="theadtd" style={{ width: "3.33%", ...styles.theadtd }}>{index+1}</td>
                <td className="theadtd" style={{ width: "32.33%", ...styles.theadtd }}></td>
                <td className="theadtd" style={{ width: "32.33%", ...styles.theadtd, ...styles.textLeft }}> 
                <Form>
                  {['checkbox'].map((type) => (
                    <div key={`default-${type}`} className="mb-0 check-upg">
                      <Form.Check // prettier-ignore
                        type={type}
                        id={`default-${type}`}
                      />
                    </div>
                  ))}
                </Form>
                   </td>
                <td className="theadtd" style={{ width: "32.33%", ...styles.theadtd, ...styles.textLeft }}></td>
            </tr>
            ))}

                    
                  
                </tbody>
              </table>
              <table style={styles.width}>
                <tr>
                  <td style={{width: '50%',textAlign: 'left', ...styles.theadth}}>
                    <p>Total Sales</p>
                  </td>
                  <td style={styles.theadth}></td>
                </tr>
              </table>
          </td>
        </tr>
      </table>
      </div>
      
        

        </>

    );

}


export default EmployeeJobCard;