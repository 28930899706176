const styles = {
  invoice: {
    width: '100%',
    maxWidth: '300px',
    margin: '0 auto',
  },
  width: {
    width: '100%',
  },
  textCenter: {
    textAlign: 'center',
  },
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  heading: {
    fontSize: '16px',
    marginBottom: '0',
    fontWeight: '600',
    textAlign: 'center',
  },
  info: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: 'normal',
    marginBottom: '5px',
    textAlign: 'center',
  },
  billno: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: 'normal',
    marginBottom: '5px',
    textAlign: 'left',
  },
  borderb: {
    borderBottom: '1px dashed #ddd',
  },
  paddingb: {
    paddingBottom: '10px',
  },
  paddingt: {
    paddingTop: '10px',
  },
  theadbg: {
    background: '#efefef',
  },
  theadth: {
    fontSize: '12px',
    fontWeight: '600',
    padding: '5px',
  },
  theadtd: {
    fontSize: '12px',
    fontWeight: '400',
    padding: '5px',
  },
  
};

export default styles;
