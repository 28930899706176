import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { handleBillingListing, handleBillingPDF, handleDeleteBilling, handleOtpVerfication, handleGetOtp } from "../../../services/billingService";
import { showToastMessage } from "../../../helpers/toastr";
import DataTable from "react-data-table-component";
import { formatDate } from "../../../helpers/formatDate";
import { formatTime } from "../../../helpers/formatTime";
import { formatAmountSymbol } from "../../../helpers/formatAmountSymbol";
import Loading from "../../../components/loader/Loading";
import ConfirmationPopupButton from "../../../components/ConfirmationPopup/ConfirmationPopupButton";
import IframeModal from "../../../components/modal/IframeModal";
import wrapModalfn from "../../../components/modal/modal";
import { paymentOption } from "../../../helpers/paymentOption";
import SearchFilter from '../../../components/pagination/SearchFilter.jsx';
import { debounce, orderBy } from 'lodash';
import confirmModal from "../../../components/modal/confirmModal.js";
import ConfirmModal from "../../../components/modal/ConfirmModal.jsx";
import BillingInvoice from "../../InvoicesPrint/BillingInvoice.jsx";
import { useSelector } from "react-redux";
import checkPermission from "../../../helpers/checkPermission.js";
import showDeletePopup from "../../../helpers/showDeletePopup.js";
import ClientJobCard from "../../InvoicesPrint/ClientJobCard.jsx";
import showOtpVerficationPopup from "../../../helpers/showOtpVerficationPopup.js";
import showEditPopup from "../../../helpers/showEditPopup.js";
import JobCard from "../../InvoicesPrint/JobCard.jsx";
import { FaPrint } from "react-icons/fa6";




const BillingListing = ({ selectGlobalOrg, filterData }) => {

    const [typeBTN, setTypeBTN] = useState('view');
    const modalHeading = typeBTN === 'view' ? 'Billing' : typeBTN === 'edit' ? 'Client Job Card' : 'Job Card';
    const userData = useSelector((state) => state.userData);
    const [UUID, setUUID] = useState('');
    const [popupID, setPopupID] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pdfData, setPdfData] = useState([]);
    const [numPages, setNumPages] = useState(1);
    const { customStyles, openModal, closeModal, setIsOpen, type, modalIsOpen } = wrapModalfn();
    const [perPage, setPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const hasAdminRole = userData?.role?.role === 'admin' ? true : false;
    const hasCoOwnerRole = userData?.role?.role === 'co-owner' ? true : false;

    const navigate = useNavigate();

    const handlePageChange = page => {
        fetchData(filterData, page, perPage);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const columns = [
        {
            name: 'Bill No',
            selector: row => row?.bill_no,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.get_customer_info?.name,
            sortable: true,
            sortFunction: (a, b) => a.get_customer_info?.name.localeCompare(b.get_customer_info?.name),
        },
        {
            name: 'Mobile Number',
            selector: row => row.get_customer_info?.phone_number,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.date ? formatDate(row.date) : '',
            sortable: true,
        },
        {
            name: 'Time',
            selector: row => row.time ? formatTime(row.time) : '',
            sortable: true,
        },
        {
            name: 'Payment Mode',
            selector: row => paymentOption(row.payment_mode),
            sortable: true,
        },
        {
            name: 'Grand Total',
            selector: row => row?.round_off_total ? formatAmountSymbol(row?.round_off_total) : formatAmountSymbol(0),
            sortable: true,
        },
    ];

    if (hasAdminRole) {
        columns.push({
            name: 'Reason',
            selector: row => row?.has_latest_delete_request?.length > 0 ? row?.has_latest_delete_request[0].reason : '',
            sortable: true,
        });
    }
    columns.push({
        name: 'Actions',
        cell: row => (
            <div className="d-flex gap-2">
                {checkPermission(userData, 'Update service-billings') && (
                    <button
                        className="icon-sm-medium round-100 icontext-blue borderwidth-0 bg-white"
                        onClick={() => {
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record is deleted, you cannot edit it.');
                            } else {
                                handleEdit(row.uuid, 'edit');
                            }
                        }}
                    >
                        <i className="mdi mdi-lead-pencil menu-icon bodytext-24"></i>
                    </button>
                )}

                {checkPermission(userData, 'Delete service-billings') && (
                    <ConfirmationPopupButton
                        handleDelete={() => {
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record already is deleted, you cannot delete it.');
                            } else {
                                handleDelete(row.uuid)
                            }
                        }}
                    // setPopupID={setPopupID} 
                    // id={row.uuid} 
                    />
                )}

                <button className="icon-sm-medium round-100 icontext-dark bg-white borderwidth-0" onClick={() => handleDownload(row.uuid, 'view')}><i className="mdi mdi-eye menu-icon bodytext-24"></i></button>

                <button className="icon-sm-medium round-100 bg-white icontext-red borderwidth-0" onClick={() => handleDownload(row.uuid, 'download')}><i class="mdi mdi-download menu-icon bodytext-24"></i></button>
            </div>
        ),
    });

    const handleEdit = async (uuid) => {
        const reason = await showEditPopup();
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    navigate(`/edit-billing/${uuid}`, { state: { reason } });
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            } else {

            }
        } else {
            if (reason !== '' && reason !== null) {
                try {
                    const requestType = 'edit';
                    const result = await handleGetOtp(uuid, reason, requestType);
                    while (true) {
                        let otp = await showOtpVerficationPopup('info');
                        if (otp !== '' && otp !== null) {
                            try {
                                const otpResult = await handleOtpVerfication(uuid, otp, reason);
                                showToastMessage('success', 'OTP verified successfully');
                                try {
                                    navigate(`/edit-billing/${uuid}`, { state: { reason } });
                                } catch (error) {
                                    console.log(error);
                                    showToastMessage('error', error.response.data.message);
                                }
                                break; // Exit the loop if everything is successful
                            } catch (error) {
                                if (error && error.response && error.response.data && error.response.data.error) {
                                    showToastMessage('error', error.response.data.error);
                                } else {
                                    showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                                }
                                console.error('Error:', error);
                                otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                            }
                        } else {
                            showToastMessage('info', 'Edit operation was cancelled.');
                            break; // Exit the loop if the operation is cancelled
                        }
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', 'An unexpected error occurred while requesting OTP.');
                }
            } else {
                showToastMessage('info', 'Edit operation was cancelled.');
            }
        }
    };



    const handleDelete = async (uuid) => {
        const reason = await showDeletePopup();
        if (reason === '' || reason == null) {
            showToastMessage("error", "Reason for deletion is required!");
            return;
        }
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleDeleteBilling(uuid, reason);
                    if (result.data) {
                        showToastMessage('success', result.data.message);
                        fetchData(filterData, currentPage, perPage);
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            }
        } else {
            const requestType = 'delete';
            const result = await handleGetOtp(uuid, reason, requestType);
            while (true) {
                let otp = await showOtpVerficationPopup('info');
                if (otp !== '' && otp !== null) {
                    try {
                        const otpResult = await handleOtpVerfication(uuid, otp, reason);
                        showToastMessage('success', 'OTP verified successfully');
                        try {
                            await handleDeleteBilling(uuid, reason);
                            fetchData(filterData, currentPage, perPage);
                        } catch (error) {
                            console.log(error);
                            showToastMessage('error', error.response.data.message);
                        }
                        break; // Exit the loop if everything is successful
                    } catch (error) {
                        if (error && error.response && error.response.data && error.response.data.error) {
                            showToastMessage('error', error.response.data.error);
                        } else {
                            showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                        }
                        console.error('Error:', error);
                        otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                    }
                } else {
                    showToastMessage('info', 'Delete operation was cancelled.');
                    break; // Exit the loop if the operation is cancelled
                }
            }
        }



    };

    const handleDownload = async (uuid, type) => {

        setTypeBTN(type);

        try {
            const result = await handleBillingPDF(uuid);

            // if(typeBTN === 'view'){
            //     openModal();
            // }else{
            openModal();
            // }


            setPdfData(result?.data?.data);
        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response?.data.message);
        }
    };

    useEffect(() => {
        console.log(filterData, 'filterDatafilterDatafilterData');
        if (filterData != undefined) {
            const parsedFilterData = JSON.parse(filterData);
            if (parsedFilterData.length === 0) {
                return;
            }
        }
        // if (filterData != undefined) {
        //     const parsedFilterData = JSON.parse(filterData);
        //     if (Object.keys(parsedFilterData).length === 0) {
        //         return;
        //     }
        // }

        const debouncedFetchData = debounce(fetchData, 300);
        const fetchDataOnChange = () => {
            debouncedFetchData(filterData, 1, perPage, searchValue);
        };

        if (searchValue) {
            fetchDataOnChange();
        } else {
            fetchData(filterData, 1, perPage, searchValue);
        }

        return () => {
            debouncedFetchData.cancel();
        };
    }, [selectGlobalOrg, perPage, searchValue, filterData]);

    const fetchData = async (filterData, page, per_page, searchText) => {
        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, option => option.value);
        const status = undefined;

        try {
            const result = await handleBillingListing(initialOrgIds, status, filterData, page, per_page, searchText);
            if (result.data) {
                console.log('hey you',result.data.data)
                setData(result.data.data);
            }
        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const conditionalRowStyles = [
        {
            when: row => row.deleted_at !== null,
            style: {
                backgroundColor: 'rgba(246, 126, 129, 0.20)',
            },
        },
        {
            when: row => row.updated_at !== null && row.deleted_at == null,
            style: {
                backgroundColor: 'rgba(224, 163, 55, 0.20)',
            },
        },
    ]

    const handleJobCard = (type) => {

        setTypeBTN(type);
        openModal();

    }


    // const iframeHeight = `${numPages * 700}px`;

    // const { closeConfirmModal, openConfirmModal, modalConfirmIsOpen, setConfirmIsOpen } = confirmModal();

    return (
        <>
            <div className="row">
                {/* <ConfirmationPopup 
                handleDelete={handleDelete} 
                // popupID={popupID} 
                // setPopupID={setPopupID} 
                /> */}
                <div className="col-lg-12 grid-margin">
                    <div className="card">
                        <div className="card-header bg-transparent">
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                <h4 className="card-title heading-h3 mb-0">Invoices Listing</h4>
                                <button
                                    className="btn button-primary d-flex gap-2 bodytext-16 align-items-center modalButtonCommon"
                                    onClick={() => handleJobCard('JobCard')}
                                >
                                    <FaPrint />

                                    Print Job card
                                </button>
                                {checkPermission(userData, 'Create service-billings') && (
                                    <NavLink to="/billing" className="btn button-primary d-flex gap-2 bodytext-16 align-items-center modalButtonCommon">
                                        <i className="fa fa-plus-circle"></i> New Billing
                                    </NavLink>
                                )}
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <DataTable
                                        columns={columns}
                                        data={data?.data}
                                        pagination
                                        paginationPerPage={25}
                                        paginationServer
                                        paginationTotalRows={data?.total}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        subHeader
                                        subHeaderComponent={<SearchFilter setSearchValue={setSearchValue} searchValue={searchValue} />}
                                        paginationComponentOptions={{
                                            rangeSeparatorText: 'of',
                                            noRowsPerPage: true,
                                        }}
                                        {...(hasAdminRole && { conditionalRowStyles })}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <IframeModal
                    modalIsOpen={modalIsOpen}
                    setIsOpen={setIsOpen}
                    closeModal={closeModal}
                    customStyles={customStyles}
                    modalHeading={modalHeading}

                    layout={
                        typeBTN === "view" ? (
                            <BillingInvoice
                                data={pdfData}
                                selectGlobalOrg={selectGlobalOrg}
                            />
                        ) : typeBTN === "download" ? (
                            <ClientJobCard data={pdfData}
                                selectGlobalOrg={selectGlobalOrg}
                            />
                        ) : (
                            <JobCard data={pdfData}
                                selectGlobalOrg={selectGlobalOrg}
                            />
                        )
                    }
                />
            </div>
        </>
    );
};

export default BillingListing;
