import axiosInstance from "./axiosConfig";

const handleService = async (formData,reason) => {
  try {
    const response = await axiosInstance.post(`save-service?reason=${reason}`, formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleServiceListing = async (formData,page, per_page, searchText) => {
  try {
    const response = await axiosInstance.get(`get-service-listing?orgId=${formData}`, {
      params: {
          page: page,
          per_page: per_page,
          searchText: searchText,
      },
  });
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleEditService = async (id, uuid,reason) => {
  try {
    const params = { id };
    
    if (uuid !== undefined) {
      params.uuid = uuid;
    }

    const response = await axiosInstance.get('edit-service', { params });
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const handleDeleteService = async (id,reason) => {
  try {
    const response = await axiosInstance.get(`delete-service?id=${id}&reason=${reason}`);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export {
  handleService,
  handleServiceListing,
  handleEditService,
  handleDeleteService
};