import swal from 'sweetalert';
import { showToastMessage } from './toastr';

const showEditPopup = async () => {
  try {
    const result = await swal({
      text: "Please provide a reason for updation.",
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter reason for deletion",
          type: "text",
          id: "edit-reason",
          required: true,
        },
      },
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false, // Prevent closing on outside click
      closeOnEsc: false, 
    });

    if (result) { // Check if the user clicked the delete button
      const reason = document.getElementById('edit-reason').value.trim(); // Trim whitespace

      if (!reason) { // Check if reason is empty
        showToastMessage("error", "Reason  is required!");
        return null; // Return null if reason is empty
      }

      return reason; // Return the reason if it's provided
    } else {
      return null; // Return null if the user cancels or dismisses the dialog
    }
  } catch (error) {
    console.error('Error showing edit popup:', error);
    return null; // Return null if there's an error
  }
};

export default showEditPopup;
