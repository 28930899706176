import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { handleCustomer } from '../../services/customerServices';
import { showToastMessage } from '../../helpers/toastr';
import { validatePhone } from '../../helpers/validatePhone';
Modal.setAppElement('#root')


const CustomerModal = ({ modalIsOpen, closeModal, customStyles, type, setIsOpen, fetchData, selectCustomer }) => {
  console.log(selectCustomer, 'fafsdfdsafdsfdsafsdfdsfsafds');
  const [btn, setBtn] = useState(false);

  const headerText = type === "add" ? 'Add Customer' : 'Edit Customer';
  const current = new Date().toISOString().split("T")[0]

  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    gender: '',
    dob: '',
    orgId: '',
    type: '',
  });

  // console.log(type,'type');
  // console.log(selectCustomer,'selectCustomer');


  useEffect(() => {

    if (type === 'edit' && selectCustomer) {
      setFormData({
        name: selectCustomer.name,
        phone: selectCustomer.phone_number,
        uuid: selectCustomer.uuid,
        email: selectCustomer.email,
        gender: selectCustomer.gender,
        dob: selectCustomer.dob,
        orgId: selectCustomer.org_id,
        type: type,
        id: selectCustomer.id,

      });
    } else {
      const newOrgId = document.getElementById('orgId').value;
      setFormData({
        name: '',
        phone: '',
        email: '',
        gender: 'male',
        dob: '',
        orgId: newOrgId,
        type: type,
      });
    }

    setErrors({});

  }, [modalIsOpen]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (formData.email && formData.email.trim() && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email.trim())) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    // Validate phone
    // const phoneValue = String(formData.phone).trim(); // Convert to string before calling trim
    // if (!phoneValue) {
    //   newErrors.phone = "Phone is required";
    //   isValid = false;
    // }

    // if (phoneValue.length !== 10) { // Check for exactly 10 digits
    //     newErrors.phone = "Phone number should be 10 digits";
    //     isValid = false;
    //   }

    const phoneValue = String(formData.phone).trim();

    if (!phoneValue) {
      newErrors.phone = "Phone number is required";
      isValid = false;
    } else if (phoneValue.length !== 10) {
      newErrors.phone = "Phone number should be 10 digits";
      isValid = false;
    } else if (!phoneValue.match(/^[6-9]\d{9}$/)) {
      newErrors.phone = "Invalid phone number format";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const processedValue = type === 'number' ? parseFloat(value) : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: processedValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const saveCustomer = async (type) => {
    if (validateForm()) {
      setBtn(true);

      try {
        let reason = null;
        if (type === 'edit') {
          reason = selectCustomer.reason;
        }
        const result = await handleCustomer(formData, reason);

        console.log(result, 'result');

        if (result.data) {
          showToastMessage('success', result.data.message)
          fetchData()
          closeModal();
          setBtn(false);
        }
      } catch (error) {
        setBtn(false);
        console.log(error);

        if (error.response.status === 404) {
          const errors = error.response.data.message
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
              errors[key].forEach((message) => {
                showToastMessage('error', message)
              });
            });
          }
        } else {
          showToastMessage('error', error.response.data.message)
        }
      }
    }
  };

  return <>
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      setIsOpen={setIsOpen}
      shouldCloseOnOverlayClick={false}
      contentLabel="Example Modal"
      className="custom-modal round-15 bg-white"
    >
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <h2 className="mb-0 heading-h4 fontweight-500">{headerText}</h2>
        <button className="bnt icon-md round-100 bodytext-22 border-0 icontext-darkgrey" onClick={closeModal}><i class="mdi mdi-close menu-icon"></i></button>
      </div>

      <form className="mx-auto mt-4 form" style={{ maxWidth: '100%' }}>
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="name" className="form-label">Name:</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={(e) => handleInputChange(e)}
                placeholder="Enter your name"
              />
              {errors.name && (
                <div className="text-danger">{errors.name}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="phoneNumber" className="form-label">Phone Number:</label>
              <input
                type="text"
                className="form-control"
                id="phoneNumber"
                name="phone"
                value={formData.phone}
                onKeyDown={(e) => validatePhone(e)}
                onChange={(e) => handleInputChange(e)}
                placeholder="Enter your phone number"
              />
              {errors.phone && (
                <div className="text-danger">{errors.phone}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label htmlFor="email" className="form-label">Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => handleInputChange(e)}
                placeholder="Enter your email address"
              />
              {errors.email && (
                <div className="text-danger">{errors.email}</div>
              )}
            </div>
          </div>
          <div className="col-md-3 col-12">
            <div className="form-group">
              <label htmlFor="gender" className="form-label">Gender:</label>
              <select className="form-control form-select" id='gender' style={{ paddingRight: '40px' }} name='gender'
                value={formData.gender}
                onChange={(e) => handleInputChange(e)}>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>
          <div className="col-md-3 col-12">
            <div className="form-group">
              <label htmlFor="dob" className="form-label">DOB:</label>
              <input
                type='date'
                className="form-control"
                id='dob'
                name='dob'
                value={formData.dob}
                onChange={(e) => handleInputChange(e)}
                placeholder='Enter BirthDate'
                max={current}
              />
            </div>
          </div>
          <div className="col-md-12 col-12 text-end">
            <button type="button" disabled={btn} className="button button-primary button-icon-left" onClick={() => saveCustomer(type)}>Submit</button>
          </div>
        </div>
      </form>
    </Modal>
  </>
}

export default CustomerModal;
