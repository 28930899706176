import React, { useEffect, useState } from 'react';
import Select from 'react-select';


export const MemberShipListing = ({ index, packages, staffData, removeRow, handlePackage, value , handleFieldChange , errors}) => {

    return (
        <div className="col-md-12" id={index} key={index}>
            <div className="added-card">
           <div className="row">
                <div className="col-lg-2 col-md-4 col-12">
                    <div className="form-group">
                        <label>Package Name</label>
                        <Select
                            name="packageId"
                            options={packages}
                            placeholder={'Select Service'}
                            isSearchable={true}
                            classNamePrefix="form"
                            value={packages.find(option => option.value === value.packageId)}
                            onChange={(option) => {
                                handlePackage(option, index);
                                handleFieldChange('packageId', option.value, index)
                            }}
                        />
                    </div>
                </div>
        
                <div className="col-lg-2 col-md-4 col-12">
                    <div className="form-group">
                        <label>Staff</label>
                        <Select
                            name="staffId"
                            value={staffData.find(option => option.value === value.staffId)}
                            isSearchable={true}
                            classNamePrefix="form"
                            options={staffData}
                            placeholder={'Select Staff'}
                            onChange={option => handleFieldChange('staffId', option.value, index)}
                        />
                    </div>
                </div>
                <div className="col-lg-2 col-md-4 col-12">
                    <div className="form-group">
                        <label>Co-Staff</label>
                        <Select
                            value={
                                staffData.filter(
                                    option =>
                                        (value?.coStaffId ?? []).includes(option.value)
                                )
                            }
                            name="coStaffId"
                            isSearchable={true}
                            options={staffData.filter(option => option.value)}
                            isMulti={true}
                            classNamePrefix="form"
                            placeholder={'Select Co-Staff'}
                            onChange={selectedOptions => handleFieldChange('coStaffId', selectedOptions.map(option => option.value), index)}
                        />
                    </div>
                </div>           
       <div className="col-lg-2 col-md-4">
        <div className="form-group">
            <label>Customer Get</label>
        <div className="percentage  margintop-10">
            <input
                name="customerGet"
                type="hidden"
                value={value.customerGet}
                className="service_total form-control"
            />
            <span className="service_total_txt">{value.customerGet}</span>
            </div>
            </div>
        </div>
       
        <div className="col-lg-2 col-md-4">
           <div className="form-group">
            <label>Customer Pay</label>
           <div className="percentage  margintop-10">
            <input
                name="customerPay"
                type="hidden"
                value={value.customerPay}
                className="service_total form-control"
            />
            <span className="service_total_txt">{value.customerPay}</span>
            </div>
            </div>
        </div>
        <div className="col-lg-2 col-md-4 col-delete">
            <button type="button" className="button button-primary button-icon" onClick={() => removeRow(index)}>
                <i class="mdi mdi-delete-empty menu-icon bodytext-24"></i>
            </button>
        </div>
    </div>
    </div>
    </div>
    );
};
