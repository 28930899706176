import CustomerModal from '../../components/customer/CustomerModal.jsx';
import DataTable from 'react-data-table-component';
import { useEffect, useState } from 'react';
import { handleCustomerListing, handleEditCustomer, handleDeleteCustomer } from '../../services/customerServices.js';
import { showToastMessage } from '../../helpers/toastr.js';
import wrapModalfn from '../../components/modal/modal.js';
import { formatAmountSymbol } from '../../helpers/formatAmountSymbol.js';
import Loading from '../../components/loader/Loading.jsx';
import HistoryModal from '../../components/customer/HistoryModal.jsx';
import ConfirmationPopupButton from '../../components/ConfirmationPopup/ConfirmationPopupButton.jsx';
import ConfirmationPopup from '../../components/ConfirmationPopup/ConfirmationPopup.jsx';
import SearchFilter from '../../components/pagination/SearchFilter.jsx';
import { debounce, orderBy } from 'lodash';
import { useSelector } from "react-redux";
import checkPermission from '../../helpers/checkPermission.js';
import showDeletePopup from '../../helpers/showDeletePopup.js';
import { conditionalRowStyles } from '../../helpers/conditionalRowStyles.js';
import { handleGetOtp } from '../../services/billingService.js';
import showOtpVerficationPopup from '../../helpers/showOtpVerficationPopup.js';
import { handleOtpVerfication } from '../../services/billingService.js';
import showEditPopup from '../../helpers/showEditPopup.js';
import EditOutStanding from '../../components/customer/EditOutStanding.jsx';




const Customer = ({ selectGlobalOrg }) => {
    const [popupID, setPopupID] = useState('');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectCustomer, setSelectedCustomer] = useState(null);
    const { customStyles, openModal, closeModal, modalIsOpen, setIsOpen, type } = wrapModalfn();
    const [modalIsOpenHis, setIsOpenHis] = useState(false);
    const [modalIsOutStading, setIsOutStanding] = useState(false);
    const [hisData, setHisData] = useState([]);
    const [perPage, setPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const userData = useSelector((state) => state.userData);

    const hasAdminRole = userData?.role?.role === 'admin' ? true : false;
    const hasCoOwnerRole = userData?.role?.role === 'co-owner' ? true : false;

    const handlePageChange = page => {
        fetchData(page, perPage, searchValue);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage, searchValue);
    };


    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            sortFunction: (a, b) => a.name.localeCompare(b.name),
            cell: row => (
                <div>
                    {row.name}
                    {row.get_customer_old_appointment_count > 1 && (
                        <span style={{ color: 'red', fontWeight: 'bold', fontSize: '0.75em', marginLeft: '5px' }}>
                            ({row.get_customer_old_appointment_count})
                        </span>
                    )}
                </div>
            ),
        },
        {
            name: 'Phone Number',
            selector: row => row.phone_number,
            sortable: true,
        },
        {
            name: 'Wallet',
            selector: row => row && row.wallet !== null ? formatAmountSymbol(row.wallet) : formatAmountSymbol(0.00),
            sortable: true,
        },
        {
            name: 'Outstanding',
            selector: row => {
                if (row && row.out_standing !== null) {
                    return formatAmountSymbol(Math.abs(row.out_standing));
                } else if (row && row.get_user_subscription?.length) {
                    const latestSubscription = row.get_user_subscription[row.get_user_subscription.length - 1];
                    return formatAmountSymbol(Math.abs(latestSubscription.outstanding_amount || 0));
                } else {
                    return formatAmountSymbol(0.00);
                }
            },
            sortable: true,
        }
    ];
    if (hasAdminRole) {
        columns.push({
            name: 'Reason',
            selector: row => row?.has_latest_delete_request?.length > 0 ? row?.has_latest_delete_request[0].reason : '',
            sortable: true,
        });
    }
    columns.push({
        name: 'Actions',
        cell: row => (
            <div className="d-flex gap-2">
                {checkPermission(userData, 'Update customers') && (
                    <button
                        className="icon-sm-medium round-100 icontext-blue borderwidth-0 bg-white"
                        onClick={() => {
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record is deleted, you cannot edit it.');
                            } else {
                                handleEdit(row.id, 'edit');
                            }
                        }}
                    >
                        <i className="mdi mdi-lead-pencil menu-icon bodytext-24"></i>
                    </button>
                )}
                {checkPermission(userData, 'Delete customers') && (
                    <ConfirmationPopupButton
                        handleDelete={() => {
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record already is deleted, you cannot delete it.');
                            } else {
                                handleDelete(row.uuid)
                            }
                        }}
                    />
                )}
                {checkPermission(userData, 'View customers')/*  && (row?.get_payment_logs?.length > 0 || row.get_customer_old_appointment_count > 0) */ && (
                    <button className="icon-sm-medium round-100 icontext-dark bg-white borderwidth-0" onClick={() => handleHistory(row)}>
                        <i className="mdi mdi-eye menu-icon bodytext-24"></i>
                    </button>
                )}
                {(row.out_standing !== null && row.out_standing !== 0) ? (
                    <button className="icon-sm-medium round-100 icontext-dark bg-white borderwidth-0" onClick={() => handleOutStandingBalance(row)}>
                        <i className="mdi mdi-cash menu-icon bodytext-24"></i>
                    </button>
                ) : null
                }

            </div>
        ),
    });

    const handleHistory = async (data) => {
        setHisData(data);
        setIsOpenHis(true);
    };
    const handleOutStandingBalance = async (data) => {
        setHisData(data);
        setIsOutStanding(true);
    }

    const closeModalHis = async () => {
        setIsOpenHis(false);
        setHisData([]);
    };
    const closeModalOutStading = async () => {
        setIsOutStanding(false);
        setHisData([]);
    }

    const handleEdit = async (uuid, type) => {
        const reason = await showEditPopup();
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleEditCustomer(uuid);
                    if (result.data) {
                        setSelectedCustomer(result.data?.data);
                        setSelectedCustomer(prevCustomer => ({
                            ...prevCustomer,
                            reason: reason,
                        }));
                        openModal(type);
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            } else {

            }
        } else {
            if (reason !== '' && reason !== null) {
                try {
                    const requestType = 'edit';
                    const result = await handleGetOtp(uuid, reason, requestType);
                    while (true) {
                        let otp = await showOtpVerficationPopup('info');
                        if (otp !== '' && otp !== null) {
                            try {
                                const otpResult = await handleOtpVerfication(uuid, otp, reason);
                                showToastMessage('success', 'OTP verified successfully');
                                try {
                                    const result = await handleEditCustomer(uuid);
                                    if (result.data) {
                                        setSelectedCustomer(result.data?.data);
                                        setSelectedCustomer(prevCustomer => ({
                                            ...prevCustomer,
                                            reason: reason,
                                        }));
                                        openModal(type);
                                    }
                                } catch (error) {
                                    console.log(error);
                                    showToastMessage('error', error.response.data.message);
                                }
                                break; // Exit the loop if everything is successful
                            } catch (error) {
                                if (error && error.response && error.response.data && error.response.data.error) {
                                    showToastMessage('error', error.response.data.error);
                                } else {
                                    showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                                }
                                console.error('Error:', error);
                                otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                            }
                        } else {
                            showToastMessage('info', 'Edit operation was cancelled.');
                            break; // Exit the loop if the operation is cancelled
                        }
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', 'An unexpected error occurred while requesting OTP.');
                }
            } else {
                showToastMessage('info', 'Edit operation was cancelled.');
            }
        }
    };

    const handleDelete = async (uuid) => {
        const reason = await showDeletePopup();
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleDeleteCustomer(uuid, reason);
                    if (result.data) {
                        showToastMessage('success', result.data.message);
                        fetchData(currentPage, perPage, searchValue);
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            } else {
                showToastMessage('info', 'Delete operation was cancelled.');
            }
        } else {
            if (reason !== '' && reason !== null) {
                try {
                    const requestType = 'delete';
                    const result = await handleGetOtp(uuid, reason, requestType);
                    while (true) {
                        let otp = await showOtpVerficationPopup('info');
                        if (otp !== '' && otp !== null) {
                            try {
                                const otpResult = await handleOtpVerfication(uuid, otp, reason);
                                showToastMessage('success', 'OTP verified successfully');
                                await handleDeleteCustomer(uuid, reason);
                                fetchData(currentPage, perPage, searchValue);
                                break; // Exit the loop if everything is successful
                            } catch (error) {
                                if (error && error.response && error.response.data && error.response.data.error) {
                                    showToastMessage('error', error.response.data.error);
                                } else {
                                    showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                                }
                                console.error('Error:', error);
                                otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                            }
                        } else {
                            showToastMessage('info', 'Delete operation was cancelled.');
                            break; // Exit the loop if the operation is cancelled
                        }
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', 'An unexpected error occurred while requesting OTP.');
                }
            } else {
                showToastMessage('info', 'Delete operation was cancelled.');
            }
        }

    };


    const customSort = (rows, selector, direction) => {
        return orderBy(rows, selector, direction);
    };

    useEffect(() => {
        const debouncedFetchData = debounce(fetchData, 300);
        const fetchDataOnChange = () => {
            debouncedFetchData(1, perPage, searchValue);
        };

        if (searchValue) {
            fetchDataOnChange();
        } else {
            fetchData(1, perPage, searchValue);
        }

        return () => {
            debouncedFetchData.cancel();
        };
    }, [selectGlobalOrg, perPage, searchValue]);

    const fetchData = async (page, per_page, searchText) => {
        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
        try {
            const result = await handleCustomerListing(initialOrgIds, page, per_page, searchText);
            if (result.data) {
                console.log(result.data.data,'fdsafsdfdsfdfasdfsd');
                setData(result.data.data);
            }
        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message);
        } finally {
            setLoading(false);
        }
    };
    const handleRefresh = async () => {
        await fetchData(); // Refresh data
    };

    return (
        <div className="row">
            {/* <ConfirmationPopup handleDelete={handleDelete} popupID={popupID} setPopupID={setPopupID} /> */}
            <HistoryModal modalIsOpen={modalIsOpenHis} closeModal={closeModalHis} customStyles={customStyles} hisData={hisData} />
            <EditOutStanding fetchData={handleRefresh} modalIsOpen={modalIsOutStading} closeModal={closeModalOutStading} customStyles={customStyles} hisData={hisData} />
            <div className="col-lg-12 grid-margin">
                <div className="card">
                    <div className="card-header bg-transparent">
                        <div className="d-flex align-items-center justify-content-between flex-wrap ">
                            <h4 className="card-title heading-h3 mb-0">Customer</h4>
                            {checkPermission(userData, 'Create customers') && (
                                <button type="button" onClick={() => openModal('add')} className="button button-primary button-icon-left mt-2">
                                    <i className="fa fa-plus-circle"></i> Add Customer
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="card-body">
                        <CustomerModal
                            modalIsOpen={modalIsOpen}
                            setIsOpen={setIsOpen}
                            closeModal={closeModal}
                            customStyles={customStyles}
                            fetchData={() => fetchData(currentPage, perPage, searchValue)}
                            selectCustomer={selectCustomer}
                            type={type}
                        />
                        <div className="row">
                            {loading ? (
                                <Loading />
                            ) : (
                                <DataTable
                                    columns={columns}
                                    data={data?.data}
                                    pagination
                                    paginationPerPage={25}
                                    paginationServer
                                    paginationTotalRows={data?.total}
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    subHeader
                                    subHeaderComponent={<SearchFilter setSearchValue={setSearchValue} searchValue={searchValue} />}
                                    paginationComponentOptions={{
                                        rangeSeparatorText: 'of',
                                        noRowsPerPage: true,
                                    }}
                                    sortFunction={customSort}
                                    {...(hasAdminRole && { conditionalRowStyles })}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Customer;
