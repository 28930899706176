

import React, { useState , useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { localStorageData } from "../../helpers/utils";
import { showToastMessage } from "../../helpers/toastr";
import ReactLogo from '../../assets/images/HairRaiserz.png'
import { handleResetPassword  } from '../../services/getLoggedUserData.js';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../actions/dataAction.js';

export const ResetPassword = ({onLogin}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [btn, setBtn] = useState(false);

    useEffect(( ) => {
        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);
        setEmail(params.get('email'));
        setToken(params.get('token'));
         }, [ ]);


  const [formData, setFormData] = useState({
    email: '',
    token:'',
    password: '',
    confirmPassword: '',
  });


  const [errors, setErrors] = useState({
    // email: '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    // Update formData.email when the email state changes
    setFormData({
      ...formData,
      email: email,
      token: token,
      
    });
  }, [email,token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    

    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();


    // Validate form
    const validationErrors = validateForm();
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }
    setBtn(true)

    // console.log(formData,'formData');

    try {
        const result = await handleResetPassword(formData);

        if(result.data && result.data.data.token){
          localStorageData.set('token',result.data.data.token)
          // localStorageData.set('authUser',result.data.data.user)
          dispatch(setUserData(result.data.data.user));
          onLogin();
          setBtn(false)
        }
        setBtn(false)
        navigate('/')

        showToastMessage('success', result.data.message)

    } catch (error) {
      setBtn(false)
        // console.log(error.response.data.message);
        showToastMessage('error', error.response.data.message)
    }
    
  };

  const validateForm = () => {
    const validationErrors = {};
  
    if (!formData.password.trim()) {
      validationErrors.password = 'Password is required';
    } else if (formData.password.length < 6) {
      validationErrors.password = 'Password must be at least 6 characters long';
    }
  
    if (formData.password !== formData.confirmPassword) {
      validationErrors.confirmPassword = 'Passwords do not match';
    }
  
    return Object.keys(validationErrors).length > 0 ? validationErrors : null;
  };

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
          <div className="d-flex w-100 auth-content">
               <div className="left-img"></div>
                <div className="auth-form-light text-left p-5">
                    <div className="auth-form-content">
                      <div className="brand-logo">
                        <img src={ReactLogo} alt="logo" />
                      </div>
                      <h1>Reset Password</h1>
                      <h4>Enter Your Email</h4>
                      <form className="pt-3" >
                        <div className="form-group" >
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            id="resetPasswordEmail"
                            value={email}
                          //   onChange={handleInputChange}
                            name="email"
                            disabled
                            placeholder="Enter your email"
                          />
                          {/* {errors.email && (
                            <div className="text-danger">{errors.email}</div>
                          )} */}
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control form-control-lg"
                            id="resetPassword"
                            value={formData.password}
                            onChange={handleInputChange}
                            name="password"
                            placeholder="New Password"
                          />
                          {errors.password && (
                            <div className="text-danger">{errors.password}</div>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control form-control-lg"
                            id="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            name="confirmPassword"
                            placeholder="Confirm Password"
                          />
                          {errors.confirmPassword && (
                            <div className="text-danger">{errors.confirmPassword}</div>
                          )}
                        </div>
                        <div className="mt-3">
                          <button
                            type="button"
                            disabled={btn}
                            onClick={handlePasswordReset}
                            className="btn btn-block btn-gradient-danger btn-lg font-weight-medium auth-form-btn w-100"
                          >
                            RESET PASSWORD
                          </button>
                        </div>
                        </form>
                        
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


