import { localStorageData } from '../helpers/utils.js';
import collapseSidebar from './Navbar.js';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ReactLogo from '.././assets/images/HairRaiserz.png';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import Profileimage from './../assets/images/profile.png';
import Bellicon from './../assets/images/bell.svg';
import BelliconNoBadge from './../assets/images/noBadgeBell.svg';
import Customericon from './../assets/images/people.svg';
import { showToastMessage } from '../helpers/toastr.js';
import { handleBillingListing, modifyRequest, handleNotificationListing } from '../services/billingService.js';
import ProgressBar from '../components/others/ProgressBar.jsx';


const Navbar = ({ handleOrgChange }) => {
  const location = useLocation();
  const [notification, setNotification] = useState('none');
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData);
  const isFirstRender = useRef(true); // Added ref to track initial mount

  const logoutUser = () => {
    localStorageData.remove('token');
    window.location.reload();
  }

  const toggleNotification = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setNotification(prev => prev === '' ? 'none' : '');
  }

  const fetchNotification = async () => {
    const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, option => option.value);
    const status = 1;

    try {
      const billingResult = await handleBillingListing(initialOrgIds, status);
      if (billingResult?.data) {
        const notificationResult = await handleNotificationListing(initialOrgIds);
        if (notificationResult?.data) {
          setData([...billingResult.data.data, ...notificationResult.data.data]);
        }
      }
    } catch (error) {
      showToastMessage('error', error.response?.data?.message || 'Error fetching notifications');
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      if (userData?.role?.role !== "employee") {
        fetchNotification();
      }
      isFirstRender.current = false;
    }
  }, [userData]);

  const handleAction = async (uuid, type, requestType) => {
    const formData = { uuid, type, requestType };

    try {
      const result = await modifyRequest(formData);
      if (result.data) {
        fetchNotification();
        showToastMessage('success', result.data.message);
      }
    } catch (error) {
      if (error.response.status === 404) {
        const errors = error.response.data.message;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).forEach(key => {
            errors[key].forEach(message => showToastMessage('error', message));
          });
        }
      } else {
        showToastMessage('error', error.response.data.message);
      }
    }
  }

  const copyOtpToClipboard = (otp) => {
    navigator.clipboard.writeText(otp)
      .then(() => {
        showToastMessage('success', 'OTP copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy OTP:', error);
        showToastMessage('error', error.response.data.message);
      });
  };

  const filteredData = data.filter(el => el.outstanding_balance_request_status === 1 || el.status === 1);
  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row nav">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <NavLink className="navbar-brand brand-logo paddingleft-30" to="/">
          <img src={ReactLogo} alt="logo" />
        </NavLink>
        <NavLink className="navbar-brand brand-logo-mini" to="/">
          <img src={ReactLogo} alt="logo" />
        </NavLink>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
          <span className="mdi mdi-menu" onClick={() => collapseSidebar('sidebar-icon-only', 'body')}></span>
        </button>
        <ul className="navbar-nav navbar-nav-right gap-4">
          <div className="form-group mb-0">
            <select
              className="form-select h-auto bg-danger-light borderwidth-1 bordercolor-light minwidth-250"
              id="orgId"
              value={localStorageData.get('organisation')}
              onChange={handleOrgChange}
            >
              {userData?.get_orgnaisation?.length > 0 && userData.get_orgnaisation.map(el => (
                <option key={el.organisation_id} value={el.organisation_id}>
                  {el.organisation.name}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <li className="nav-item nav-logout d-none">
              <NavLink to='/profile' className="nav-link icon-md round-100 icon-light">
                <i className="mdi mdi-account-circle"></i>
              </NavLink>
            </li>
          </div>
        </ul>
        <ul className="navbar-nav navbar-nav-right navbar-nav-items-right gap-4">
          <div className="d-flex gap-2 align-items-center">
            <li className="nav-item nav-logout d-lg-block dropdown">
              <a className="nav-link icon-md round-100 justify-content-center" onClick={toggleNotification} href="#">
                {filteredData.length > 0 || data.length > 0 && userData?.role?.role == "admin" ? (
                  <img src={Bellicon} alt="Profile" />
                ) : (
                  <img src={BelliconNoBadge} alt="Profile" />
                )}
              </a>
              {userData?.role?.role !== "manager" && userData?.role?.role !== "accountant" && (
                <div
                  style={{ display: notification }}
                  className="dropdown-menu dropdown-menu-left navbar-dropdown preview-list show notification-menu"
                  aria-labelledby="notificationDropdown"
                  data-bs-popper="none"
                >
                  <h6 className="p-3 mb-0">Notifications</h6>
                  <div>
                    {data.length > 0 && (
                      data.map((d, index) => (
                        <a key={d.id} className="dropdown-item grid grid-inline preview-item">
                          <div className="preview-item-content">
                            <p className="mb-0">
                              {d.request_type === 'edit' ? (
                                <span>You have an edit request. Here is the reason: <b>{d?.reason}</b></span>
                              ) : (
                                <span>You have a delete request. Here is the reason: <b>{d?.reason}</b></span>
                              )}
                              <br />
                              <span><b>{d?.otp}</b> is your one-time password valid for the next 10 minutes.</span>
                            </p>
                          </div>
                          <div className="justify-end ml-4">
                            <button type="button" className="btn btn-success mb-2 mr-4 float-end" onClick={() => copyOtpToClipboard(d?.otp)}>
                              Copy OTP
                            </button>
                          </div>
                        </a>
                      ))
                    )}

                  </div>
                  <div>
                    {filteredData.length > 0 || data?.length > 0 ? (
                      filteredData.map((el, index) => (
                        <div key={el.uuid}>
                          {index > 0 && <div className="dropdown-divider"></div>}
                          <a className="dropdown-item preview-item">
                            <div className="preview-item-content">
                              <h6 className="preview-subject font-weight-normal mb-1">
                                {el.outstanding_balance_request_status === 1 ? 'Outstanding Invoice Request' : 'Special Discount Request'}
                              </h6>
                              <p className="mb-0">
                                <span><b>{el?.get_customer_info?.name}</b> {el.outstanding_balance_request_status} has an outstanding balance but wants to make an invoice with 70% payment for the next service.</span>
                              </p>
                            </div>
                            <div className="preview-item-action">
                              <button type="button" className="button button-icon button-error" onClick={() => handleAction(el.uuid, 'rejected', el.outstanding_balance_request_status === 1 ? 'out_standing' : 'discount')}>
                                <i className="mdi mdi-close"></i>
                              </button>
                              <button type="button" className="button button-icon button-success" onClick={() => handleAction(el.uuid, 'approved', el.outstanding_balance_request_status === 1 ? 'out_standing' : 'discount')}>
                                <i className="mdi mdi-check"></i>
                              </button>
                            </div>
                          </a>
                        </div>
                      ))
                    ) : (
                      <h5 className="text-center">No Notifications</h5>
                    )}
                  </div>
                </div>
              )}
            </li>
          </div>
        </ul>
        
            {/*<div className="right-ico-res d-flex gap-1 align-items-center">
              <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                <span className="mdi mdi-menu" onClick={() => collapseSidebar('sidebar sidebar-offcanvas active', 'mobile')}></span>
              </button>
               <a className="nav-link icon-md round-100 justify-content-center align-items-center d-flex" onClick={toggleNotification} href="#">
                  {filteredData.length > 0 || data.length > 0 && userData?.role?.role == "admin" ? (
                    <img src={Bellicon} alt="Profile" />
                  ) : (
                    <img src={BelliconNoBadge} alt="Profile" /> 
                  )}
              </a> 
            </div>*/}
          
      </div>
    </nav>
  );
}

export default Navbar;
