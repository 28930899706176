import swal from 'sweetalert';
import { showToastMessage } from './toastr';

const showOtpVerficationPopup = async (status = 'info') => {
  const icons = {
    info: "info",
    success: "success",
    error: "error",
  };

  while (true) {
    try {
      const result = await swal({
        text: "We have sent an OTP to the admin. Please ask the administration for the OTP.",
        content: {
          element: "input",
          attributes: {
            placeholder: "Enter OTP for deletion",
            type: "text",
            id: "delete-otp",
            required: true,
          },
        },
        icon: icons[status] || "info",
        buttons: true,
        dangerMode: true,
        closeOnClickOutside: false, // Prevent closing on outside click
        closeOnEsc: false, 
      });

      if (result) { // Check if the user clicked the delete button
        const otp = document.getElementById('delete-otp').value.trim(); // Trim whitespace

        if (!otp) { // Check if OTP is empty
          showToastMessage("error", "OTP is required!");
          status = 'error'; // Set status to error if OTP is empty
          continue; // Continue the loop if OTP is empty
        }

        return otp; // Return the OTP if it's provided
      } else {
        return null; // Return null if the user cancels or dismisses the dialog
      }
    } catch (error) {
      console.error('Error showing OTP popup:', error);
      return null; // Return null if there's an error
    }
  }
};

export default showOtpVerficationPopup;
