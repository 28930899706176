import axiosInstance from "./axiosConfig";

const handleDashboardData = async (formData) => {
  try {
    const response = await axiosInstance.post('get-dashboard-data', formData);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export {
    handleDashboardData,
};