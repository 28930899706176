import { useEffect, useState } from 'react';
import './Permission.css';
import { handlePermission, handlePermissionListing } from '../../services/permissionService';
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter';
import { showToastMessage } from '../../helpers/toastr';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Permission = ({ selectGlobalOrg }) => {

  const userData = useSelector((state) => state.userData);
  const navigate = useNavigate();

  const hasAdminRole = userData?.role?.role === 'admin' ? true : false;
  const hasCoOwnerRole = userData?.role?.role === 'co-owner' ? true : false;

  // console.log(hasAdminRole,hasCoOwnerRole,'isLoggedIn');

    if(!hasAdminRole  && !hasCoOwnerRole){
      navigate('/unauthorised')
    }

  const [data, setData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [formData, setFormData] = useState({
    value: '',
    org_id: '',
    permission_id: '',
    role_id: '',
  });

  useEffect(() => {
    fetchData();
  }, [selectGlobalOrg]);

  const fetchData = async () => {
    try {
      const result = await handlePermissionListing();
      console.log(result, 'resultresultresult');

      if (result?.data) {
        setData(result?.data?.data?.permissions);
        setRoles(result?.data?.data?.roles);
      }
    } catch (error) {
      console.log(error);
      showToastMessage('error', error.response.data.message);
    }
  };

  const array = roles.filter((i) => i.name !== 'employee');



  const setPermissions = async (checked, roleID, permissionID) => {
    const newOrgId = document.getElementById('orgId').value;

    // Use the updated formData directly
    const updatedFormData = {
      ...formData,
      value: checked,
      permission_id: permissionID,
      role_id: roleID,
      org_id: newOrgId,
    };

    setFormData(updatedFormData);

    // Update roles state with new permission
    const updatedRoles = roles.map((role) => {
      if (role.id === roleID) {
        const newPermissions = checked
          ? [...role.permissions, { id: permissionID }]
          : role.permissions.filter((p) => p.id !== permissionID);
        return { ...role, permissions: newPermissions };
      }
      return role;
    });

    setRoles(updatedRoles);

    try {
        const result = await handlePermission(updatedFormData);

        if (result && result.data && result.data.success === true) {
            showToastMessage('success', result.data.message)
        }

    } catch (error) {
        console.log(error);
        if(error?.response?.status === 404){
            const errors = error.response.data.message
            if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).forEach((key) => {
                    errors[key].forEach((message) => {
                        showToastMessage('error', message)
                    });
                });
            }
        }else{
            showToastMessage('error', error.response.data.message)
        }
    }
};


  return (
    <div className="table-container">
      <div className="row">
        <div className="col-lg-12 grid-margin">
          <div className="card">
            <div className="card-header bg-transparent">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="card-title heading-h3 mb-0">Permissions</h4>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <table className="permission">
                  <thead>
                    <tr>
                      <th>Permissions</th>
                      {array.map((role) => (
                        <th key={role.id}>{capitalizeFirstLetter(role.name)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((permission) => (
                      <tr key={permission.id}>
                        <td>{permission.name}</td>
                        {array.map((role) => (
                          <td key={role.id}>
                            <input
                              name="permission"
                              value={permission.id}
                              checked={role.permissions.some((i) => i.id === permission.id)}
                              disabled={role.name === 'admin' }
                              onChange={(e) =>
                                setPermissions(e.target.checked, role.id, permission.id)
                              }
                              type="checkbox"
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Permission;
