export function formatDateTime(datetimeString) {
    // Create a Date object from the datetime string
    const date = new Date(datetimeString);
  
    // Extract hours and minutes
    const hours = date.getHours(); // Use getHours to respect local time zone
    const minutes = date.getMinutes();
  
    // Determine AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';
  
    // Convert to 12-hour format
    const formattedHours = hours % 12 || 12; // Handle midnight
  
    // Pad minutes with leading zero if needed
    const formattedMinutes = minutes.toString().padStart(2, '0');
  
    // Construct the formatted time string
    const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;
  
    return formattedTime;
  }