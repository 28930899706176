import { useState } from "react";

const wrapToggleFn = () => {

    const [openMenu, setOpenMenu] = useState(null);

    const toggleNavbar = (menuId) => {
    setOpenMenu((prevMenu) => (prevMenu === menuId ? null : menuId));
    };

    const isMenuOpen = (menuId) => {
    return openMenu === menuId ? "collapse show" : "collapse";
    };

    return {
        toggleNavbar,
        isMenuOpen,
    }

}

export default wrapToggleFn;
