import React, { useEffect, useState } from "react";
import { Listing } from "../../../components/appointments/Listing";
import { handleBilling, handleCustomerSearch } from "../../../services/billingService";
import { showToastMessage } from "../../../helpers/toastr";
import { handleEditCustomer } from "../../../services/customerServices";
import { CustomerDetail } from "../../../components/appointments/CustomerDetail";
import { handleStaffListing } from "../../../services/staffServices";
import { handleEditService, handleServiceListing } from "../../../services/service";
import { formatAmount } from "../../../helpers/formatAmount";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import { roundOffAmount } from "../../../helpers/roundOffAmount";
import { validatePhone } from "../../../helpers/validatePhone";
import { MultipleProductListing } from "../../../components/products/MultipleProductListing";
import { handleEditProduct, handleProductListing } from "../../../services/productService";
import { handleEditProductBilling, handleProductBilling } from "../../../services/productBillingService";
import { selectOptions } from "../../../helpers/paymentOption";

const EditProductBilling = ({ selectGlobalOrg }) => {

    const navigate = useNavigate();

    const [popupID, setPopupID] = useState('');

    const [showDiv, setShowDiv] = useState('none');
    const [showGender, setShowGender] = useState('');
    const [showDetail, setShowDetail] = useState('none');
    const [searchdata, setSearchData] = useState([]);
    const [value, setValue] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [staffData, setStaffData] = useState([]);
    const [products, setProducts] = useState([]);
    const [btn, setBtn] = useState(false);
    const [errors, setErrors] = useState({});
    const uuid = useParams('uuid');
    const [billingData, setBillingData] = useState([]);
    const location = useLocation();
    const reason = location.state?.reason;


    const [formData, setFormData] = useState({

        // customer
        name: '',
        phone: '',
        email: '',
        gender: 'male',
        orgId: '',
        // wallet:0,
        refId: '',
        type: '',
        // date:'',
        // time:'',

        // Service Billing
        billingRemark: '',
        subTotal: '',
        // billingTax:'',
        // discountSymbol:'%',
        // discountedAmount:'',
        // discount:'',
        paymentMode: '',
        // grandTotal:'',
        // specialDiscount:'',
        // walletStatus:false,
        // walletAmount:'0.00',
        // roundOffGrandTotal:0,
        // taxEnabled:false,

        // Multiple billing service
        products: [
            {
                productId: '',
                staffId: '',
                quantity: '1',
                price: '',
                total: '',
            }
        ]
    });

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        // if (!formData.name.trim()) {
        //   newErrors.name = "Name is required";
        //   isValid = false;
        // }

        if (!formData.paymentMode.trim()) {
            newErrors.paymentMode = "Payment mode is required";
            isValid = false;
        }


        // Validate phone
        // const phoneValue = formData.phone.trim(); 

        // if (!phoneValue) {
        //   newErrors.phone = "Phone is required";
        //   isValid = false;
        // }else if(phoneValue.length !== 10){
        //     newErrors.phone = "Phone number should be 10 numbers";
        //     isValid = false;
        // }else if(!phoneValue.match(/^[6-9]\d{9}$/)){
        //     newErrors.phone = "Invalid phone number format";
        //     isValid = false;
        // }



        setErrors(newErrors);



        return isValid;
    };

    const handleInputChange = (e) => {

        const { name, value, type, checked } = e.target;
        const processedValue = type === 'number' ? parseFloat(value) : value;


        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: processedValue,
        }));



        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };



    const handleKeyUp = (event) => {
        const inputValue = event.target.value;
        setValue(inputValue);
    };

    // const searchCustomerNumber = async (value) => {

    //     const orgID = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

    //     try {
    //         const result = await handleCustomerSearch(value, orgID);

    //         if (result.data) {
    //             if (result.data?.data.length > 0) {
    //                 setSearchData(result.data?.data)
    //                 setShowDiv('block')
    //             } else {
    //                 setShowDiv('none')
    //                 setSearchData([]);
    //             }
    //         }

    //     } catch (error) {
    //         console.log(error);
    //         showToastMessage('error', error.response.data.message)
    //     }

    // }

    // useEffect(() => {
    //     const delayDebounceFn = setTimeout(() => {
    //         if (value) {
    //             searchCustomerNumber(value)
    //         } else {
    //             setShowDiv('none')
    //             setSearchData([]);
    //             setValue('')
    //         }
    //     }, 1000);
    //     return () => clearTimeout(delayDebounceFn);
    // }, [value, selectGlobalOrg]);



    const handleCustomerClick = async (id) => {

        try {
            const result = await handleEditCustomer(id);

            if (result.data) {
                setSelectedCustomer(result.data?.data)
                setShowDetail('block')
                setShowDiv('none')
                setShowGender('none')
            } else {
                setShowGender('')
                setShowDiv('none')
                setShowDetail('none')
                setSelectedCustomer([]);
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }

    // useEffect(() => {

    //     if (selectedCustomer && selectedCustomer.length != 0) {



    //         setFormData({
    //             name: selectedCustomer.name,
    //             phone: selectedCustomer.phone_number,
    //             uuid: selectedCustomer.uuid,
    //             email: selectedCustomer.email,
    //             gender: selectedCustomer.gender,
    //             dob: selectedCustomer.dob,
    //             orgId: selectedCustomer.org_id,
    //             customerId: selectedCustomer.id,
    //             wallet:selectedCustomer.wallet,
    //             type:'add',
    //             date:'',
    //             time:'',
    //             refId:'',

    //             billingRemark:'',
    //             subTotal:'0.00',
    //             billingTax:'0.00',
    //             paymentMode:'',
    //             grandTotal:'0.00',
    //             roundOffGrandTotal:0,
    //             taxEnabled:false,

    //             // Multiple billing service
    //             products: [
    //                 {
    //                     productId:'',
    //                     staffId:'',
    //                     quantity:'1',
    //                     price:'',
    //                     total:'',
    //                 }
    //             ]

    //         });
    //     } else {
    //         const newOrgId = document.getElementById('orgId').value;
    //         setFormData({

    //         // customer
    //         name: '',
    //         phone: '',
    //         email: '',
    //         gender: 'male',
    //         orgId: newOrgId,
    //         refId:'',
    //         type: 'add',
    //         wallet:0,
    //         date:'',
    //         time:'',

    //         // Service Billing
    //         billingRemark:'',
    //         subTotal:'0.00',
    //         billingTax:'0.00',
    //         paymentMode:'',
    //         grandTotal:'0.00',
    //         roundOffGrandTotal:0,
    //         taxEnabled:false,

    //         // Multiple billing service

    //         products: [
    //             {
    //                 productId:'',
    //                 staffId:'',
    //                 quantity:'1',
    //                 price:'',
    //                 total:'',
    //             }
    //         ]
    //         });
    //     }

    // }, [selectedCustomer]);



    useEffect(() => {


        if (billingData && billingData.length != 0) {

            // console.log(selectGlobalOrg,'selectGlobalOrg');

            if (selectGlobalOrg && (billingData.org_id != selectGlobalOrg)) {

                showToastMessage('error', 'Organizaton changed and that record not exits in this record')
                navigate('/product-billing-listing')
            }

            let productData;

            if (billingData.get_multiple_products && billingData.get_multiple_products.length > 0) {
                productData = billingData.get_multiple_products.map(service => ({
                    productId: service.product_id,
                    staffId: service.staff_id,
                    quantity: service.quantity,
                    price: service.price,
                    total: service.total,
                }));
            } else {

                productData = [{
                    productId: '',
                    staffId: '',
                    quantity: '1',
                    price: '',
                    total: '',
                }];
            }

            // let w

            handleCustomerClick(billingData?.get_customer_info?.uuid)



            setFormData({
                name: billingData.get_customer_info?.name,
                phone: billingData.get_customer_info?.phone_number,
                gender: billingData.get_customer_info?.gender,
                orgId: billingData.org_id,
                customerId: billingData.get_customer_info?.id,
                type: 'edit',
                date: billingData?.date,
                time: billingData?.time,
                refId: billingData.get_customer_info?.reference_id,


                billingRemark: billingData?.billing_remark,
                subTotal: billingData?.sub_total,
                paymentMode: billingData?.payment_mode,

                uuid: billingData?.uuid,

                // Multiple billing service
                products: productData

            });
        }

    }, [billingData, selectGlobalOrg]);


    const fetchBilling = async (uuid) => {
        try {
            const result = await handleEditProductBilling(uuid);

            if (result.data) {
                setBillingData(result.data?.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }
    }



    useEffect(() => {

        fetchStaff();
        fetchProducts();
        fetchBilling(uuid);

    }, [selectGlobalOrg, uuid]);

    //   useEffect for fetch staff members

    const fetchStaff = async () => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

        try {
            const result = await handleStaffListing(initialOrgIds, 'employee', '0');

            if (result.data) {
                setStaffData(result.data?.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }

    const fetchProducts = async () => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);
        const status = 0;

        try {
            const result = await handleProductListing(initialOrgIds, status);
            if (result?.data) {
                setProducts(result?.data.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        }

    }


    const addRow = () => {
        setFormData({
            ...formData,
            products: [...formData.products, { productId: '', staffId: '', quantity: '1', price: '0.00', total: '0.00' }]
        });
    };

    const removeRow = (index) => {
        setFormData(prevState => {
            if (prevState.products.length <= 1) {
                // Show toast message indicating that at least one service is required
                showToastMessage("error", "At least one product is required");
                return prevState; // Don't modify state
            }

            const updatedProducts = [...prevState.products];
            updatedProducts.splice(index, 1);



            let subtotal = 0;

            updatedProducts.forEach(service => {

                subtotal += service.price * service.quantity;

            });


            // grandTotal -= discountAmt;

            // let grand = roundOffAmount(grandTotal)

            return {
                ...prevState,
                products: updatedProducts,
                subTotal: formatAmount(subtotal),

            };

        });
    };


    const calculateTotalForRow = (quantity, price, tax) => {
        const totalPrice = parseFloat(quantity) * parseFloat(price);
        // const totalTax = totalPrice * (parseFloat(tax) / 100);
        return formatAmount(totalPrice);
    };

    const handleFieldChange = (fieldName, value, index) => {
        console.log(fieldName, value, index, 'index');

        // let amountToDeduct;

        setFormData(prevState => {
            const updatedProducts = [...prevState.products];

            if (index !== undefined) {
                updatedProducts[index] = {
                    ...updatedProducts[index],
                    [fieldName]: value,
                    total: calculateTotalForRow(
                        fieldName === 'quantity' ? value : updatedProducts[index]?.quantity,
                        fieldName === 'price' ? value : updatedProducts[index]?.price,
                        // '18'
                    ),
                    // tax:'18',
                };
            }

            let subtotal = 0;

            updatedProducts.forEach(service => {

                subtotal += service.price * service.quantity;

            });


            // grandTotal -= discountAmt;

            // let grand = roundOffAmount(grandTotal)

            return {
                ...prevState,
                products: updatedProducts,
                subTotal: formatAmount(subtotal),
                // grandTotal: formatAmount(grandTotal),
                // roundOffGrandTotal:grand,
                // discount: discount,
                // discountedAmount: formatAmount(discountAmt)
            };
        });


    };


    const handleProduct = async (option, index) => {
        const id = option.value;
        if (!id) {
            return false;
        }
        try {
            const result = await handleEditProduct(id);
            if (result.data) {
                const newData = result.data?.data;

                setFormData(prevState => {
                    const updatedProducts = [...prevState.products];

                    updatedProducts[index] = {
                        ...updatedProducts[index],
                        productId: newData.id,
                        price: newData.price,
                        quantity: '1',
                        total: calculateTotalForRow('1', newData.price)
                    };

                    let subtotal = 0;

                    updatedProducts.forEach(service => {

                        subtotal += service.price * service.quantity;

                    });

                    return {
                        ...prevState,
                        products: updatedProducts,
                        subTotal: formatAmount(subtotal),
                    };
                });

            }
        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message);
        }
    };

    const options = [{ value: "", label: "Select Product" }];
    if (products && products.length > 0) {
        const groupedProducts = {};

        products.forEach((el) => {
            if (el.deleted_at === null) {
                if (!groupedProducts[el.product_id]) {
                    groupedProducts[el.product_id] = [];
                }

                groupedProducts[el.product_id].push({
                    value: el.id,
                    label: el.name,
                    cat_id: el.get_brand_name?.name
                });
            }
        });

        for (const product in groupedProducts) {
            options.push({
                label: groupedProducts[product][0].cat_id,
                options: groupedProducts[product],
            });
        }
    }


    // console.log(products,'optionsoptionsoptions');

    const staffOptions = [{ value: "", label: "Select Staff" }];
    if (staffData && staffData.length > 0) {
        staffData.forEach((el) => {
            if (!el.deleted_at && el.role?.role === 'employee') { // Check if deleted_at is null or undefined and role is 'employee'
                staffOptions.push({
                    value: el.id,
                    label: el.name,
                });
            }
        });
    }



    const handleSubmit = async (e) => {

        console.log(formData, 'formData');

        // alert('Product billing underprogress')

        // return false;

        if (validateForm()) {

            setBtn(true)

            try {
                const result = await handleProductBilling(formData, reason);

                if (result.data) {
                    showToastMessage('success', result.data.message)
                    const newOrgId = document.getElementById('orgId').value;


                    navigate('/product-billing-listing')

                    setBtn(false)



                }
            } catch (error) {
                setBtn(false)
                console.log(error);
                if (error.response.status === 404) {
                    const errors = error.response.data.message
                    if (errors && Object.keys(errors).length > 0) {
                        Object.keys(errors).forEach((key) => {
                            errors[key].forEach((message) => {
                                showToastMessage('error', message)
                            });
                        });
                    }
                } else {
                    showToastMessage('error', error.response.data.message)
                }
            }

        }


    }

    return (

        <>

            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center bg-transparent">
                    <div className="p-header d-flex flex-column gap-2 align-items-start justify-content-start">
                        <h2 className="bodytext-30 fontweight-600 text-dark mb-0">Product Billing</h2>

                    </div>
                    <NavLink to='/product-billing-listing' className="button button-primary">View All Invoices</NavLink>
                </div>
                <div className="card-body">
                    <form id="billingform" autoComplete="off">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="phone">Mobile Number *</label>
                                            <p className="form-control">{formData.phone}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Customer">Customer Name *</label>
                                            <p className="form-control">{formData.name}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6" >
                                        <div className="form-group gender_check">
                                            <label htmlFor="gender">Gender</label>
                                            <p className="form-control">{formData.gender}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group gender_check">
                                            <label htmlFor="gender">How did you reach us?</label>
                                            <p className="form-control">{formData.refId}</p>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="Customer">Date</label>
                                        <p className="form-control">{formData.date}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="Customer">Time</label>
                                        <p className="form-control">{formData.time}</p>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <blockquote className="blockquote blockquote-primary customer_detail round-10 " >
                                    <CustomerDetail
                                        selectedCustomer={selectedCustomer}
                                    />
                                </blockquote>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 margintop-5 marginbottom-15">
                                <button type="button" onClick={addRow} id="btn_add_row" className="button button-primary button-icon-left"><i className="fa fa-plus"></i> Add Product</button>
                            </div>
                            <div className="col-md-12 mt-4">

                                <div className="table-billing-form" id="item_table">

                                    <div className="row">

                                        {formData.products.map((value, index) => (
                                            <MultipleProductListing
                                                key={index}
                                                index={index}
                                                value={value}
                                                services={options} // Pass your options here
                                                staffData={staffOptions} // Pass your staffOptions here
                                                handleProduct={handleProduct}
                                                handleFieldChange={handleFieldChange}
                                                removeRow={removeRow}
                                            />
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row justify-content-between mt-2">
                            <div className="col-md-12" id="button_add">
                                <div className="form-group">
                                    <label for="billing_remark">Billing Remark</label>
                                    <textarea
                                        className="form-control billing_remark"
                                        id="billing_remark"
                                        name="billingRemark"
                                        value={formData.billingRemark}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                        }}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="col-md-6 mt-4" id="package">
                                <table className="table table-bordered table-billing">
                                    <tbody>
                                        <tr>

                                            <th style={{ width: '50%' }}>Sub Total</th>
                                            <td className="text-right">
                                                <span id="subTotal">{formData?.subTotal || 0.00}</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th>Payment Mode</th>
                                            <td className="text-right">
                                                <select
                                                    className="payment_mode form-select form-control"
                                                    name="paymentMode"
                                                    value={formData?.paymentMode}
                                                    onChange={(e) => {
                                                        handleInputChange(e);
                                                    }}
                                                >
                                                    <option value="">Select Payment Mode</option>
                                                    {selectOptions('deleteOption')}
                                                    {/* <option value="cash">Cash</option>
                                                <option value="cc">Card</option>
                                                <option value="gpay">Gpay/UPI</option> */}
                                                </select>
                                                {errors.paymentMode && (
                                                    <div className="text-danger">{errors.paymentMode}</div>
                                                )}
                                            </td>
                                        </tr>






                                        {/* <tr className="amount_due">
                                        <th>Grand Total:</th>
                                        <td className="text-right">
                                            <span className="currencySymbol" style={{ display: 'inline-block' }} ></span>
                                            <span id="grandTotal">{formatAmount(formData?.roundOffGrandTotal) }</span>
                                           
                                        </td>
                                    </tr> */}
                                    </tbody>
                                </table>
                                <button
                                    type="button"
                                    onClick={handleSubmit}
                                    disabled={btn}
                                    className="button button-primary table-billing-btn" >Save Bill</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default EditProductBilling;
