import StaffModal from "../../components/staff/StaffModal.jsx";
// import wrapStaffModalfn from "../../components/staff/StaffModal.js";
import { handleStaffListing, handleEditStaff, handleDeleteStaff } from "../../services/staffServices.js";
import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter.js";
import { showToastMessage } from "../../helpers/toastr.js";
import wrapModalfn from "../../components/modal/modal.js";
import { formatDate } from "../../helpers/formatDate.js";
import Loading from "../../components/loader/Loading.jsx";
import ConfirmationPopupButton from "../../components/ConfirmationPopup/ConfirmationPopupButton.jsx";
import SearchFilter from "../../components/pagination/SearchFilter.jsx";
import { debounce } from 'lodash';
import { useSelector } from "react-redux";
import checkPermission from "../../helpers/checkPermission";
import showDeletePopup from "../../helpers/showDeletePopup.js";
import EmployeeJobCard from "../InvoicesPrint/EmployeeJobCard.jsx";
import IframeModal from "../../components/modal/IframeModal.jsx";
import { handleGetOtp } from '../../services/billingService.js';
import showOtpVerficationPopup from '../../helpers/showOtpVerficationPopup.js';
import { handleOtpVerfication } from '../../services/billingService.js';
import { conditionalRowStyles } from '../../helpers/conditionalRowStyles.js';
import showEditPopup from "../../helpers/showEditPopup.js";

const Staff = ({ selectGlobalOrg }) => {
    const modalHeading = "Client Job Card"
    const userData = useSelector((state) => state.userData);
    const [popupID, setPopupID] = useState('');
    const [data, setData] = useState([]);

    const [pdfData, setPdfData] = useState([]);

    const [loading, setLoading] = useState(true);

    const [selectedStaff, setSelectedStaff] = useState([]);

    const { customStyles, openModal, closeModal, setIsOpen, type, modalIsOpen } = wrapModalfn();

    const [perPage, setPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');

    const [iframeModalIsOpen, iframeSetIsOpen] = useState(false);

    function openIframeModal() {
        iframeSetIsOpen(true);
    }

    function closeIframeModal() {
        iframeSetIsOpen(false);
    }
    const hasAdminRole = userData?.role?.role === 'admin' ? true : false;
    const hasCoOwnerRole = userData?.role?.role === 'co-owner' ? true : false;


    const handlePageChange = page => {
        fetchData(page, perPage, searchValue);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage, searchValue);
    };
    useEffect(() => {
        // if(filterData != undefined){
        //     const parsedFilterData = JSON.parse(filterData);
        //     if (parsedFilterData.length === 0) { 
        //         return; 
        //     }
        // }
        const debouncedFetchData = debounce(fetchData, 300);
        const fetchDataOnChange = () => {
            debouncedFetchData(1, perPage, searchValue || '');
        };

        if (searchValue) {
            fetchDataOnChange();
        } else {
            fetchData(1, perPage, searchValue);
        }

        return () => {
            debouncedFetchData.cancel();
        };
    }, [selectGlobalOrg, perPage, searchValue]);

    const fetchData = async (page, per_page, searchText) => {

        const initialOrgIds = Array.from(document.getElementById('orgId').selectedOptions, (option) => option.value);

        try {
            const role = "undefind";
            const status = "undefind";
            const result = await handleStaffListing(initialOrgIds, role, status, page, per_page, searchText);
            if (result.data) {
                setData(result.data.data)
            }

        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response.data.message)
        } finally {
            setLoading(false)
        }

    }

    const handleDownload = async (data) => {
        try {
            // const result = await handleBillingPDF(uuid);
            openIframeModal();
            setPdfData(data);
        } catch (error) {
            console.log(error);
            showToastMessage('error', error.response?.data.message);
        }
    };


    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            sortFunction: (a, b) => a.name.localeCompare(b.name),
        },
        {
            name: 'Phone Number',
            selector: row => row.phone_number,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            sortFunction: (a, b) => a?.email?.localeCompare(b?.email),
        },
        {
            name: 'Role',
            selector: row => row && row.role !== null ? capitalizeFirstLetter(row.role.role) : null,
            sortable: true,
        },
        {
            name: 'Joining Date',
            selector: row => row && row.joining_date !== null ? formatDate(row.joining_date) : null,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row && row.status == 0 ? 'Active' : 'Inactive',
            sortable: true,
            sortFunction: (a, b) => {
                const statusA = a.status === 0 ? 'Active' : 'Inactive';
                const statusB = b.status === 0 ? 'Active' : 'Inactive';
                return statusA.localeCompare(statusB);
            }
        },
    ];

    if (hasAdminRole) {
        columns.push({
            name: 'Reason',
            selector: row => row?.has_latest_delete_request ? row.has_latest_delete_request.reason : '',
            sortable: true,
        });
    }
    columns.push({
        name: 'Actions',
        cell: row => (
            <div className="d-flex gap-2">
                {checkPermission(userData, 'Update staff') && (
                    <button
                        className="icon-sm-medium round-100 icontext-blue borderwidth-0 bg-white"
                        onClick={() => {
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record is deleted, you cannot edit it.');
                            } else {
                                handleEdit(row.uuid, 'edit');
                            }
                        }}
                    >
                        <i className="mdi mdi-lead-pencil menu-icon bodytext-24"></i>
                    </button>
                )}
                {checkPermission(userData, 'Delete staff') && (
                    <ConfirmationPopupButton
                        handleDelete={() => {
                            if (row.deleted_at !== null) {
                                showToastMessage('error', 'Record is already deleted, you cannot delete it.');
                            } else {
                                handleDelete(row.uuid);
                            }
                        }
                        }
                    />
                )}

                <button className="icon-sm-medium round-100 icontext-dark bg-white borderwidth-0" onClick={() => handleDownload(row)}><i className="mdi mdi-eye menu-icon bodytext-24"></i></button>

                {/* <button className="icon-sm-medium round-100 bg-white icontext-red borderwidth-0" onClick={() => handleDelete(row.uuid)}><i class="mdi mdi-delete-empty menu-icon bodytext-24"></i></button> */}
                {/* Add more action buttons as needed */}
            </div>
        )

    })


    const handleDelete = async (uuid) => {
        const reason = await showDeletePopup();
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleDeleteStaff(uuid, reason);
                    if (result.data) {
                        showToastMessage('success', result.data.message);
                        fetchData(currentPage, perPage);
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            } else {
                showToastMessage('info', 'Delete operation was cancelled.');
            }
        } else {
            if (reason !== '' && reason !== null) {
                try {
                    const requestType = 'delete';
                    const result = await handleGetOtp(uuid, reason, requestType);
                    while (true) {
                        let otp = await showOtpVerficationPopup('info');
                        if (otp !== '' && otp !== null) {
                            try {
                                const otpResult = await handleOtpVerfication(uuid, otp, reason);
                                showToastMessage('success', 'OTP verified successfully');
                                await handleDeleteStaff(uuid, reason);
                                fetchData(currentPage, perPage);
                                break; // Exit the loop if everything is successful
                            } catch (error) {
                                if (error && error.response && error.response.data && error.response.data.error) {
                                    showToastMessage('error', error.response.data.error);
                                } else {
                                    showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                                }
                                console.error('Error:', error);
                                otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                            }
                        } else {
                            showToastMessage('info', 'Delete operation was cancelled.');
                            break; // Exit the loop if the operation is cancelled
                        }
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', 'An unexpected error occurred while requesting OTP.');
                }
            } else {
                showToastMessage('info', 'Delete operation was cancelled.');
            }
        }

    };



    const handleEdit = async (uuid, type) => {
        const reason = await showEditPopup();
        if (hasAdminRole || hasCoOwnerRole) {
            if (reason !== '' && reason !== null) {
                try {
                    const result = await handleEditStaff(uuid);

                    if (result.data) {
                        setSelectedStaff(result?.data?.data)
                        setSelectedStaff(prevStaff => ({
                            ...prevStaff,
                            reason: reason,
                        }));
                        openModal(type)
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', error.response.data.message);
                }
            } else {

            }
        } else {
            if (reason !== '' && reason !== null) {
                try {
                    const requestType = 'edit';
                    const result = await handleGetOtp(uuid, reason, requestType);
                    while (true) {
                        let otp = await showOtpVerficationPopup('info');
                        if (otp !== '' && otp !== null) {
                            try {
                                const otpResult = await handleOtpVerfication(uuid, otp, reason);
                                showToastMessage('success', 'OTP verified successfully');
                                try {
                                    const result = await handleEditStaff(uuid);

                                    if (result.data) {
                                        setSelectedStaff(result?.data?.data)
                                        setSelectedStaff(prevStaff => ({
                                            ...prevStaff,
                                            reason: reason,
                                        }));
                                        openModal(type)
                                    }
                                } catch (error) {
                                    console.log(error);
                                    showToastMessage('error', error.response.data.message);
                                }
                                break; // Exit the loop if everything is successful
                            } catch (error) {
                                if (error && error.response && error.response.data && error.response.data.error) {
                                    showToastMessage('error', error.response.data.error);
                                } else {
                                    showToastMessage('error', 'An unexpected error occurred during OTP verification.');
                                }
                                console.error('Error:', error);
                                otp = await showOtpVerficationPopup('error'); // Show the popup again with error status
                            }
                        } else {
                            showToastMessage('info', 'Edit operation was cancelled.');
                            break; // Exit the loop if the operation is cancelled
                        }
                    }
                } catch (error) {
                    console.log(error);
                    showToastMessage('error', 'An unexpected error occurred while requesting OTP.');
                }
            } else {
                showToastMessage('info', 'Edit operation was cancelled.');
            }
        }
    };

    return (
        <>
            <div className="row">

                {/* <ConfirmationPopup 
               handleDelete={handleDelete} 
               popupID={popupID}
               setPopupID={setPopupID}
               /> */}

                <div className="col-lg-12 grid-margin">
                    <div className="card">
                        <div className="card-header bg-transparent">
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                <h4 class="card-title heading-h3 mb-0">Staff</h4>
                                {checkPermission(userData, 'Create staff') && (
                                    <button type="button"
                                        onClick={() => openModal('add')}
                                        className="button button-primary button-icon-left mt-2">
                                        <i class="fa fa-plus-circle"></i>  Add Staff
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <StaffModal
                                    modalIsOpen={modalIsOpen}
                                    setIsOpen={setIsOpen}
                                    closeModal={closeModal}
                                    customStyles={customStyles}
                                    fetchData={(e) => fetchData(currentPage, perPage)}
                                    type={type}
                                    selectedStaff={selectedStaff}
                                />
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <>
                                        <DataTable
                                            columns={columns}
                                            data={data?.data}
                                            pagination
                                            paginationPerPage={25}
                                            paginationServer
                                            paginationTotalRows={data?.total}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            subHeader
                                            subHeaderComponent={<SearchFilter
                                                setSearchValue={setSearchValue}
                                                searchValue={searchValue}
                                            />}
                                            paginationComponentOptions={{
                                                rangeSeparatorText: 'of',
                                                noRowsPerPage: true,
                                            }}
                                            {...(hasAdminRole && { conditionalRowStyles })}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <IframeModal
                modalIsOpen={iframeModalIsOpen}
                setIsOpen={iframeSetIsOpen}
                closeModal={closeIframeModal}
                customStyles={customStyles}
                modalHeading={modalHeading}
                layout={
                    <EmployeeJobCard
                        data={pdfData}
                    />
                }
            />


        </>
    )

}
export default Staff;