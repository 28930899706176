export const conditionalRowStyles = [
    {
        // When has_latest_delete_request is an object and deleted_at is not null
        when: row => row.has_latest_delete_request && typeof row.has_latest_delete_request === 'object' && !Array.isArray(row.has_latest_delete_request) && row.deleted_at !== null,
        style: {
            backgroundColor: 'rgba(246, 126, 129, 0.15)',
        },
    },
    {
        // When has_latest_delete_request is an array and deleted_at is not null
        when: row => Array.isArray(row.has_latest_delete_request) && row.has_latest_delete_request.length > 0 && row.deleted_at !== null,
        style: {
            backgroundColor: 'rgba(246, 126, 129, 0.15)',
        },
    },
    {
        // When has_latest_delete_request is an object and deleted_at is null, and it has a reason
        when: row => row.has_latest_delete_request && typeof row.has_latest_delete_request === 'object' && !Array.isArray(row.has_latest_delete_request) && row.deleted_at === null && row.has_latest_delete_request.reason !== null,
        style: {
            backgroundColor: 'rgba(224, 163, 55, 0.15)',
        },
    },
    {
        // When has_latest_delete_request is an array, deleted_at is null, and it has a reason
        when: row => Array.isArray(row.has_latest_delete_request) && row.has_latest_delete_request.length > 0 && row.deleted_at === null && row.has_latest_delete_request[0].reason !== null,
        style: {
            backgroundColor: 'rgba(224, 163, 55, 0.15)',
        },
    },
];
