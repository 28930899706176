import { useEffect, useState } from 'react';
// import axiosInstance from './axiosConfig';

import Modal from 'react-modal';
import Loading from '../loader/Loading';
import DataTable from 'react-data-table-component';
import { formatAmountSymbol } from '../../helpers/formatAmountSymbol';
import { formatDate } from '../../helpers/formatDate';
import { formatDateTime } from '../../helpers/formatDateTime';
import { formatTime } from '../../helpers/formatTime';
import { paymentOption } from '../../helpers/paymentOption';
import BillingInvoice from '../../pages/InvoicesPrint/BillingInvoice';
import { handleBillingPDF } from '../../services/billingService';
import { showToastMessage } from '../../helpers/toastr';
import wrapModalfn from '../modal/modal';
import IframeModal from '../modal/IframeModal';
import { handleCustomerListing, handleOutStandingBalance } from '../../services/customerServices';
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";

Modal.setAppElement('#root')
const EditOutStanding = ({ modalIsOpen, closeModal, customStyles, hisData }) => {
    const [formData, setFormData] = useState({ paid_outstanding: '' });
    const [error, setError] = useState(''); // State to store validation error message
    const [btn, setBtn] = useState(false); // Assuming this is for enabling/disabling the button
    const [pdfData, setPdfData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        setLoading(false);
    }, [hisData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const numericValue = parseFloat(value) || 0;
        const outstandingAmount = Math.abs(hisData.out_standing);
        if (numericValue > outstandingAmount) {
            setError(`You can not add more than ${formatAmountSymbol(outstandingAmount)}`);
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: outstandingAmount
            }));
        } else {
            // Clear error message and update form data with the valid value
            setError('');
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: numericValue
            }));
        }
    };


    const handleSubmit = async () => {
        const uuid = hisData.uuid;
        try {
            const initialOrgIds = hisData.org_id;
            const result = await handleOutStandingBalance(initialOrgIds,formData.paid_outstanding, uuid);
            showToastMessage('success', result.data.message);
            closeModal();
           window.location.reload()
        } catch (error) {
            if (error.response.status === 404) {
                const errors = error.response.data.message;
                if (errors && Object.keys(errors).length > 0) {
                    Object.keys(errors).forEach((key) => {
                        errors[key].forEach((message) => {
                            showToastMessage('error', message);
                        });
                    });
                }
            } else {
                showToastMessage('error', error.response.data.message);
            }
        }
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            shouldCloseOnOverlayClick={false}
            contentLabel="Add Outstanding Amount"
            className="custom-modal round-15 bg-white"
        >
            <div className="d-flex align-items-center justify-content-between">
                <h2 className="mb-0 heading-h4 fontweight-500">Add Outstanding Amount</h2>
                <button
                    className="btn icon-md round-100 bodytext-22 border-0 icontext-darkgrey"
                    onClick={closeModal}
                >
                    <i className="mdi mdi-close menu-icon"></i>
                </button>
            </div>
            <div className="card-body">
                <form id="billingform" autoComplete="off">
                    <div className="col-md-12 mt-4">
                        <div className="text-right position-relative paddingbottom-25">
                            <div className="discount-wrap">
                                <input
                                    className="text-left discount input-sm form-control"
                                    id="outstanding"
                                    value={formData.outstanding}
                                    name="paid_outstanding"
                                    type="number"
                                    onChange={handleInputChange}
                                    min="0" // Ensure input cannot be negative
                                />
                            </div>
                            {error && <p className="text-danger">{error}</p>} {/* Display error message */}
                        </div>
                        <div className="flex flex-wrap gap-2 float-end">
                            <button
                                type="button"
                                onClick={() => handleSubmit('save')}
                                disabled={btn || !!error} // Disable button if there's an error
                                className="button button-primary table-billing-btn"
                            >
                                Add Outstanding Balance
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
}

export default EditOutStanding;