export function formatAmountSymbol(amount) {

    if (isNaN(amount) || amount === null) {
        return '₹ 0.00';
    } 
    
    
      // Format amount with Indian currency symbol (₹)
      const formattedAmount = `₹ ${parseFloat(amount).toFixed(2)}`;
    
      return formattedAmount;
}